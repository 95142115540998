<template>
  <div class="float-end" v-if="documentId">
    <v-btn
      :disabled="!nextId"
      color="primary"
      class="me-2"
      icon
      small
      outlined
      @click="redirectOnNextAndPrevious(nextId)"
    >
      <v-icon> mdi-arrow-down-bold-outline </v-icon>
    </v-btn>
    <v-btn
      :disabled="!prevId"
      color="primary"
      icon
      small
      outlined
      @click="redirectOnNextAndPrevious(prevId)"
    >
      <v-icon> mdi-arrow-up-bold-outline </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "NextAndPreviousCommon",
  props: ["documentId", "nextId", "prevId"],
  methods: {
    redirectOnNextAndPrevious(id) {
      this.$emit("idChange", id);
    },
  },
};
</script>
