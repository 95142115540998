<template>
  <v-bottom-sheet
    v-model="dialog"
    persistent
    max-width="80%"
    inset
    overlay-opacity="0.5"
  >
    <v-card>
      <v-card-title class="pa-2">
        <v-spacer />
        <v-btn
          color="primary"
          small
          :disabled="!valid || (accessRight.length < 2 && accessRight.includes('show'))"
          :loading="loading"
          @click="saveRemarks"
        >
          {{$t('remarks.save')}}
        </v-btn>
        <v-btn
          icon
          @click="$emit('update:dialog', false);"
        >
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-h5 px-4">
        <v-form
          ref="formAttachedDocuments"
          v-model="valid"
          lazy-validation
          :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
        >
          <Alert :alert.sync="alert" :message="message" />
          <v-row>
            <v-col cols="12">
              <v-card
                class="mx-auto"
                flat
              >
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-notebook-edit-outline</v-icon>
                  <h6 class="ms-2 align-self-center font-weight-regular">{{$t('employee.attachedDocuments')}}</h6>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row :dense="true">
                    <v-col cols="12">
                      <v-simple-table
                        dense
                        class="custom-table elevation-1 item-table"
                        fixed-header
                        height="350"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th :style="{width: '60px'}">
                                {{ $t('employee.date') }}
                              </th>
                              <th :style="{width: '50px'}">
                                {{ $t('employee.employee') }}
                              </th>
                              <th :style="{ width: '60px' }">
                                {{ $t("employee.documentType") }}
                              </th>
                              <th :style="{ width: '140px' }">
                                {{ $t("employee.details") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("employee.file") }}
                              </th>
                              <th class="text-center" :style="{width: '50px'}">
                                {{ $t('employee.action') }}
                              </th>
                              <th class="text-center" :style="{ width: '40px' }">
                                {{ $t("employee.show") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="attchedDocumentItems.length <= 0">
                              <td colspan="8" class="text-center">{{$t('common.noDataFound')}}</td>
                            </tr>
                            <tr v-else v-for="(item, index) in attchedDocumentItems" style="vertical-align: bottom" :key="index" :class="index+1 == attchedDocumentItems.length ? 'newRow' : ''">
                              <td>
                                <span>
                                  {{profileById.date_format ? formatDateDDMMYYYY(item.date) : parseDateYYYYMMDD(item.date)}}
                                </span>
                              </td>
                              <td :style="{wordBreak: 'break-word'}">
                                <span>
                                  {{item.written_by}}
                                </span>
                              </td>
                              <td :style="{ wordBreak: 'break-word' }">
                                <span>
                                  {{item.document_type}}
                                </span>
                              </td>
                              <td>
                                <v-textarea
                                  class="date-input"
                                  rows="3"
                                  dense
                                  hide-details="auto"
                                  :rules="detialsRequired"
                                  v-model.trim="item.details"
                                />
                              </td>
                              <td>
                                <v-file-input
                                  hide-details="auto"
                                  v-model="item.image_path"
                                  :rules="[(value) => !!item.image_path || !!item.file_path || $t('formRules.fileRequired')]"
                                  class="date-input ma-0 pa-0 text-center"
                                >
                                  <template v-slot:selection="{ text }">
                                    <v-chip
                                      small
                                      label
                                      color="primary"
                                    >
                                      {{ text }}
                                    </v-chip>
                                  </template>
                                  <template v-if="item.id" #prepend-inner>
                                    {{item.file_path.match(/\/([^\/]+)$/)[1].split('?')[0].split('-')[1]}}
                                  </template>
                                </v-file-input>
                              </td>
                              <td class="text-center">
                                  <v-row>
                                    <v-col cols="6" class="text-end">
                                      <v-btn
                                        icon
                                        color="primary"
                                        small
                                        @click="addRow"
                                        :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                                      >
                                        <v-icon color="primary">
                                          mdi-plus
                                        </v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="6" class="text-start">
                                        <v-btn
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(index)"
                                          :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                  </v-row>
                              </td>
                              <td class="text-center">
                                <span class="mx-1">
                                  <v-btn :disabled="!item.id" class="my-2" color="primary" x-small @click="viewFile(index)">{{ $t('employee.view') }}</v-btn>
                                </span>
                                <span>
                                  <v-btn :disabled="!item.id" class="my-2" color="primary" x-small @click="downloadFile(index)">{{ $t('employee.show') }}</v-btn>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { mapGetters } from 'vuex';
import Alert from "@/components/Alert"
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';

export default {
  name: "AttachedDocumentsDialog",
  props: ["dialog", "status", "docId", "docType"],
  components: {
    Alert
  },
  data () {
    return {
      alert: false,
      loading: false,
      valid: false,
      message: '',
      attchedDocumentItems: [],
    }
  },
  computed: {
    remarksValid() {
      return [
        v => !!v || this.$t('formRules.titleRequired'),
        v => !v || !/^\s*$/.test(v) || this.$t('formRules.titleRequired'),
        v => !v || (v && v.length <= 800) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('remarks.remarks'), type: this.$t('common.less'), size: 800 }),
      ];
    },
    detialsRequired() {
      return [
        v => !!v || this.$t('formRules.detialsRequired'),
      ]
    },
    isFieldReadable () {
      return !this.status ? false : true;
    },
    ...mapGetters({
      attachedDocumentsList: 'attachedDocuments/attachedDocumentsList',
      profileById: 'profile/profileById',
      accessRight: "accessRight"
    })
  },
  watch: {
    async dialog(val) {
      if (!val) {
        this.alert = false;
        this.loading = false;
        this.valid = false;
        this.message = '';
        this.attchedDocumentItems = [];
        this.$refs.formAttachedDocuments.reset();
      } else {
        this.attchedDocumentItems = [];
        await this.$store.dispatch('attachedDocuments/GetAttachedDocuments', this.docId + "?document_type=" + this.docType)
      }
    },
    attachedDocumentsList: {
      handler: function() {
        this.attchedDocumentItems = [];
        if (this.attachedDocumentsList.length > 0) {
          this.attachedDocumentsList.forEach(element => {
            this.attchedDocumentItems.push(Object.assign({}, {...element}));
          })
        }
        this.attchedDocumentItems.push(
          {
            date: new Date().toLocaleDateString('sv-SE'),
            written_by: this.$store.state.user.user.name,
            document_type: this.docType,
            details: null
          }
        );
      },
      deep: true,
    }
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    parseDateYYYYMMDD: parseDateYYYYMMDD, 
    openStatus(){
      this.$emit('update:dialog', false);
    },
    closeStatus(){
      this.$emit('update:dialog', false);
    },
    addRow() {
      this.attchedDocumentItems.push({
        date: new Date().toLocaleDateString('sv-SE'),
        written_by: this.$store.state.user.user.name,
        document_type: this.docType,
        details: null
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(".item-table div.v-data-table__wrapper")
      this.$vuetify.goTo(newRow, {container: wrapper});
    },
    deleteRow(index) {
      if (this.attchedDocumentItems[index].id) {
        this.$store.dispatch('attachedDocuments/DeleteAttachedDocuments',this.attchedDocumentItems[index].id).then(() => {
          this.$store.dispatch('attachedDocuments/GetAttachedDocuments', this.docId + "?document_type=" + this.docType)
        })
      } else {
        this.attchedDocumentItems.splice(index,1);
      }
    },
    downloadFile(index) {
      const fileUrl = this.attchedDocumentItems[index].file_path.split('?')[0];
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "file.pdf";
      link.target = "_blank";
      link.click();
    },
    viewFile(index) {
      const fileUrl = this.attachedDocumentsList[index].file_path.split('?')[0];
      const fileExtension = fileUrl.split('.').pop().toLowerCase();
      if (['pdf', 'xls', 'xlsx', 'docx', 'xlsm', 'csv', 'doc'].includes(fileExtension)) {
        const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}`;
        const link = document.createElement('a');
        link.href = viewerUrl
        link.target = '_blank';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        const image = new Image();
        image.src = fileUrl;
        const imageWindow = window.open('', '_blank');
        imageWindow.document.write(image.outerHTML);
        imageWindow.document.close();
      } else {
        const link = document.createElement('a');
        link.href = fileUrl
        link.target = '_blank';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }
    },
    async saveRemarks() {
      if(this.$refs.formAttachedDocuments.validate()){
        this.loading = true;
        let saveAttchedDocumentsData = Object.assign({
          editedRecords: [],
          records: []
        })
        
        let createFormData = new FormData();
        let updatedFormData = new FormData()

        let count = 0
        this.attchedDocumentItems.forEach((element, index) => {
          if (element.id) {
            // update records
            updatedFormData.append(`editedRecords[${index}].details`, element.details);
            updatedFormData.append(`editedRecords[${index}].document_type`, this.docType);
            updatedFormData.append(`editedRecords[${index}].written_by`, element.written_by);
            updatedFormData.append(`editedRecords[${index}].document_id`, this.docId);
            updatedFormData.append(`editedRecords[${index}].image`, element.image_path ? element.image_path : element.file_path);
            updatedFormData.append(`editedRecords[${index}].date`, element.date);
            updatedFormData.append(`editedRecords[${index}].id`, element.id);
            saveAttchedDocumentsData.editedRecords.push({
              details: element.details,
              document_type: this.docType,
              written_by: element.written_by,
              document_id: this.docId,
              image: element.file_path,
              date: element.date,
            })
          } else {
            // create records
            if (this.docType) {
              createFormData.append(`records[${count}].details`, element.details);
              createFormData.append(`records[${count}].document_type`, this.docType);
              createFormData.append(`records[${count}].written_by`, element.written_by);
              createFormData.append(`records[${count}].document_id`, this.docId);
              createFormData.append(`records[${count}].image`, element.image_path ? element.image_path : element.file_path);
              createFormData.append(`records[${count}].date`, element.date);
              saveAttchedDocumentsData.records.push({
                details: element.details,
                document_type: this.docType,
                written_by: element.written_by,
                document_id: this.docId,
                image: element.file_path,
                date: element.date,
              })
              count ++;
            }
          }
        });
        if(saveAttchedDocumentsData.records.length > 0 || saveAttchedDocumentsData.editedRecords.length > 0) {
          // create records
          if (saveAttchedDocumentsData.records.length > 0) {
            let addData = await this.$store.dispatch('attachedDocuments/SetAttachedDocuments', createFormData).catch((e) => {
              this.loading = false;
              console.log(e)
            });
            if(addData) {
              this.$refs.formAttachedDocuments.reset();
              this.loading = false;
              this.attchedDocumentItems = [];
              await this.$store.dispatch('attachedDocuments/GetAttachedDocuments', this.docId + "?document_type=" + this.docType)
            }
          }

          // updated records
          if (saveAttchedDocumentsData.editedRecords.length > 0) {
            let editData = await this.$store.dispatch('attachedDocuments/UpdateAttachedDocuments', updatedFormData).catch((e) => {
              this.loading = false;
              console.log(e)
            });
            if(editData) {
              this.$refs.formAttachedDocuments.reset();
              this.loading = false;
              this.attchedDocumentItems = [];

              await this.$store.dispatch('attachedDocuments/GetAttachedDocuments', this.docId + "?document_type=" + this.docType)
            }
          }
        } else {
          this.message = "formRules.documentAttachedAlert";
          this.alert = true;
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    }
  }
}
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>