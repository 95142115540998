<template>
  <v-combobox
    dense
    v-model="item.selectedProduct"
    :items="items"
    item-value="item_code"
    item-text="description"
    flat
    return-object
    hide-no-data
    hide-details
    cache-items
    required
    single-line
    :search-input.sync="search"
    @blur="autoselectMenu = false"
    @change="onChange(item.selectedProduct, index)"
    @dblclick="redirectOnEditProductPage"
    :menu-props="{ value: autoselectMenu }"
  >
    <template v-slot:no-data>
      <div class="centered-slot">
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
    <template v-slot:append>
      <v-icon :color="autoselectMenu ? 'primary' : undefined" @click="toggle">{{
        autoselectMenu ? "mdi-menu-up" : "mdi-menu-down"
      }}</v-icon>
    </template>
    <template v-slot:selection="data">
      <span @dblclick="redirectOnEditProductPage">{{
        `${data.item.item_code}`
      }}</span>
    </template>
    <template v-slot:item="data">
      <div
        class="font-weight-medium"
        style="font-size: 0.8125rem; display: flex; align-item: center"
      >
        {{ `${data.item.item_code}` }}. {{ `${data.item.item_description}` }} -
        {{ `${data.item.unit_price}` }} -
        {{
          documentType.includes(data.item.document_type) &&
          data.item.document_type
            ? $t(`product.${data.item.document_type}`)
            : data.item.document_type
        }}
        -
        {{
          data.item.documentDate
            ? `${data.item.documentDate.split("T")[0]}`
            : null
        }}
      </div>
    </template>
  </v-combobox>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductItemSelect",
  props: ["item", "index", "indexItem", "itemId", "selectedCustomer", "table"],
  data() {
    return {
      search: "",
      items: [],
      autoselectMenu: false,
      searchItemList: {},
      documentType: [
        "Quotation",
        "Order",
        "Delivery_Note",
        "Invoice",
        "Credit_Invoice",
        "Proforma_Invoice",
        "Receipt_Tax_Invoice",
        "Return_Certificate",
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      productList: "product/productItemsByCustomer",
    }),
  },
  watch: {
    productList() {
      this.items = [];
      this.items = this.productList;
    },
    async selectedCustomer(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.isLoading = true;
        if (
          this.table === "PurchaseOrder" ||
          this.table === "SupplierDeliveryNote" ||
          this.table === "SupplierInvoice" ||
          this.table === "SupplierCreditInvoice"
        ) {
          await this.$store.dispatch("product/GetProductsByPost", {
            page: 1,
            limit: 20,
            table: this.table,
            supplier_id: this.selectedCustomer?.id,
            order_by: "item_code|asc",
            type: "purchase",
          });
        } else {
          await this.$store.dispatch("product/GetProductsByPost", {
            page: 1,
            limit: 20,
            table: this.table,
            customer_id: this.selectedCustomer?.id,
            order_by: "item_code|asc",
          });
        }
        this.isLoading = false;
        this.items = this.productList;
      }
    },
  },
  async mounted() {
    if (this.selectedCustomer?.id) {
      this.isLoading = true;
      if (
        this.table === "PurchaseOrder" ||
        this.table === "SupplierDeliveryNote" ||
        this.table === "SupplierInvoice" ||
        this.table === "SupplierCreditInvoice"
      ) {
        await this.$store.dispatch("product/GetProductsByPost", {
          page: 1,
          limit: 20,
          table: this.table,
          supplier_id: this.selectedCustomer?.id,
          order_by: "item_code|asc",
          type: "purchase",
        });
      } else {
        await this.$store.dispatch("product/GetProductsByPost", {
          page: 1,
          limit: 20,
          table: this.table,
          customer_id: this.selectedCustomer?.id,
          order_by: "item_code|asc",
        });
      }
      this.isLoading = false;
      this.items = this.productList;
    }
  },
  methods: {
    toggle() {
      this.autoselectMenu = !this.autoselectMenu;
    },
    async onChange(item, idx) {
      let search = this.search;
      if (this.search && this.selectedCustomer) {
        this.item.selectedProduct = {
          description: search,
          item_code: search,
        };
        this.isLoading = true;
        if (
          this.type === "PurchaseOrder" ||
          this.table === "SupplierDeliveryNote" ||
          this.table === "SupplierInvoice" ||
          this.table === "SupplierCreditInvoice"
        ) {
          await this.$store.dispatch("product/GetProductsByPost", {
            page: 1,
            limit: 20,
            table: this.table,
            supplier_id: this.selectedCustomer?.id,
            where_like: search ? "item_code|" + search : search,
            order_by: "item_code|asc",
            type: "purchase",
          });
        } else {
          await this.$store.dispatch("product/GetProductsByPost", {
            page: 1,
            limit: 20,
            table: this.table,
            customer_id: this.selectedCustomer?.id,
            where_like: search ? "item_code|" + search : search,
            order_by: "item_code|asc",
          });
        }
        this.isLoading = false;
        let res = await this.$store.dispatch("product/GetProduct");
        this.searchItemList = await res.find((x) => x.item_code == search);
        if (this.searchItemList) {
          this.$emit("onProductChange", this.searchItemList, this.index);
        } else {
          this.item.selectedProduct = null;
        }
      } else {
        this.$emit("onProductChange", item, idx, "dropdown", this.indexItem);
      }
      this.autoselectMenu = false;
    },
    redirectOnEditProductPage() {
      if (this.item.selectedProduct && this.itemId) {
        this.$router.push({ path: "/item/ItemAction/" + this.itemId });
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.centered-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
