<template>
  <v-dialog
    v-model="dialog"
    width="500"
    @click:outside="$emit('update:dialog', false)"
  >
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t("auxiliaryTable.confirm") }}
        <v-spacer />
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="text-body-1 mt-4">
        {{ $t("formRules.refreshToken") }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="text-center text-capitalize"
          outlined
          small
          @click="$emit('update:dialog', false)"
        >
          {{ $t("auxiliaryTable.confirm") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "RefreshToken",
  props: ["dialog"],
};
</script>
