<template>
  <div>
    <v-btn
      color="primary"
      class="me-2"
      small
      outlined
      :disabled="!contentRendered || !disabled || isValidForPrint || (accessRight.length < 2 && accessRight.includes('show'))"
      @click="openDialog"
    >
      <v-icon left>
        mdi-file-download-outline
      </v-icon>
      {{$t('quotationPdf.generatePDF')}}
    </v-btn>
    <v-btn
      v-if="type !== 'EmployeeReportPDF' && type !== 'CustomerReportPDF' && type !== 'SupplierReportPDF' && type !== 'ItemReportPDF' && type !== 'BankReportPDF' && type !== 'QuotationReportPDF' && type !== 'OrderReportPDF' && type !== 'ProformaReportPDF' && type !== 'CreditInvoiceReportPDF' && type !== 'InvoiceReportPDF' && type !== 'DepositReportPDF' && type !== 'DeliveryReportPDF' && type !== 'ReceiptReportPDF' && type !== 'SalesReportPDF' && type !== 'DebitReportPDF' && type !== 'SupplierDebtsReport' && type !== 'AccountingCardReportPDF' && type !== 'OrderPDF' && type !== 'ReceiptPDF' && type !== 'DepositPDF' && type !== 'QuotationPDF' && type !== 'ReceiptTaxInvoiceReportPDF' && type !== 'TrialBalanceReportPDF' && type !== 'ReturnCertificateReportPDF' && type !== 'JournalEntryReportPDF' && type !== 'PurchaseOrderReportPDF' && type !== 'PurchaseOrderPDF' && type !== 'SupplierDeliveryNoteReportPdf' && type !== 'SupplierDeliveryNotePDF' && type !== 'SupplierInvoiceReportPdf' && type !== 'SupplierInvoicePDF' && type !== 'SupplierCreditInvoiceReportPdf' && type !== 'SupplierCreditInvoicePDF' && type !== 'PurchaseReportPDF' && type !== 'SupplierPaymentReportPDF' && type !== 'SupplierPaymentPdf' && type !== 'CashFlowReportPDF' && type !== 'GenerateEvaluationReport'"
      color="primary"
      class="me-2"
      small
      outlined
      :disabled="!contentRendered || !disabled"
      @click="previewPdf"
    >
      <v-icon left>
        mdi-file-eye-outline
      </v-icon>
      {{$t('quotationPdf.previewPDF')}}
    </v-btn>
    <v-btn
      v-if="type !== 'EmployeeReportPDF' && type !== 'CustomerReportPDF' && type !== 'SupplierReportPDF' && type !== 'ItemReportPDF' && type !== 'BankReportPDF' && type !== 'QuotationReportPDF' && type !== 'OrderReportPDF' && type !== 'ProformaReportPDF' && type !== 'CreditInvoiceReportPDF' && type !== 'InvoiceReportPDF' && type !== 'DepositReportPDF' && type !== 'DeliveryReportPDF' && type !== 'ReceiptReportPDF' && type !== 'ReceiptTaxInvoiceReportPDF' && type !== 'ReturnCertificateReportPDF' && type !== 'JournalEntryReportPDF' && type !== 'PurchaseOrderReportPDF' && type !== 'SupplierDeliveryNoteReportPdf' && type !== 'SupplierInvoiceReportPdf' && type !== 'SupplierCreditInvoiceReportPdf' && type !== 'SupplierPaymentReportPDF' && type !== 'GenerateEvaluationReport'"
      color="primary"
      small
      outlined
      :disabled="!contentRendered || !disabled || isValidForPrint"
      @click="emailDialog = true"
    >
      <v-icon left>
        mdi-email-fast-outline
      </v-icon>
      {{$t('common.sendByMail')}}
    </v-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="download"
      :preview-modal="preview"
      :paginate-elements-by-height="1100"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-content-width="800px"
      pdf-orientation="portrait"
      ref="html2Pdf"
      @progress="onProgress($event)"
      @beforeDownload="beforeDownload($event)"
    >
      <!-- <slot name="pdfcontent"></slot> -->
      <PrintOrder slot="pdf-content" v-if="type === 'OrderPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintDelivery slot="pdf-content" v-else-if="type === 'DeliveryPDF'" :isPDFPreview="isPDFPreview" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintInvoice slot="pdf-content" v-else-if="type === 'InvoicePDF'" :isPDFPreview="isPDFPreview" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintReceipt slot="pdf-content" v-else-if="type === 'ReceiptPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintDeposit slot="pdf-content" v-else-if="type === 'DepositPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintCreditInvoice slot="pdf-content" v-else-if="type === 'CreditInvoicePDF'" :isPDFPreview="isPDFPreview" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintProforma slot="pdf-content" v-else-if="type === 'ProformaInvoicePDF'" @domRendered="domRendered()" class="me-2 mb-1" :isPDFPreview="isPDFPreview" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintDebitReport slot="pdf-content" v-else-if="type === 'DebitReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :isFocused="isFocused" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintEmployeeReport slot="pdf-content" v-else-if="type === 'EmployeeReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintCustomerReport slot="pdf-content" v-else-if="type === 'CustomerReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierReport slot="pdf-content" v-else-if="type === 'SupplierReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintProductReport slot="pdf-content" v-else-if="type === 'ItemReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintBankReport slot="pdf-content" v-else-if="type === 'BankReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintQuotationReport slot="pdf-content" v-else-if="type === 'QuotationReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintOrderReport slot="pdf-content" v-else-if="type === 'OrderReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintProformaReport slot="pdf-content" v-else-if="type === 'ProformaReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintDeliveryReport slot="pdf-content" v-else-if="type === 'DeliveryReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintCreditInvoiceReport slot="pdf-content" v-else-if="type === 'CreditInvoiceReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintInvoiceReport slot="pdf-content" v-else-if="type === 'InvoiceReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintDepositReport slot="pdf-content" v-else-if="type === 'DepositReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintReceiptReport slot="pdf-content" v-else-if="type === 'ReceiptReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSalesReport slot="pdf-content" v-else-if="type === 'SalesReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :isFocused="isFocused" :withPrice="withPrice" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintAccountingCardReport slot="pdf-content" v-else-if="type === 'AccountingCardReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintReceiptTaxInvoiceReport slot="pdf-content" v-else-if="type === 'ReceiptTaxInvoiceReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintReceiptTaxInvoice slot="pdf-content" v-else-if="type === 'ReceiptTaxInvoicePDF'" @domRendered="domRendered()" class="me-2 mb-1" :isPDFPreview="isPDFPreview" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintQuotation slot="pdf-content" v-else-if="type === 'QuotationPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintTrialBalanceReportPDF slot="pdf-content" v-else-if="type === 'TrialBalanceReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintReturnCertificateReportPDF slot="pdf-content" v-else-if="type === 'ReturnCertificateReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintJournalEntryReport slot="pdf-content" v-else-if="type === 'JournalEntryReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintRetunCertificate slot="pdf-content" v-else-if="type === 'RetunCertificatePDF'" :isPDFPreview="isPDFPreview" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintPurchaseOrderReport slot="pdf-content" v-else-if="type === 'PurchaseOrderReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintPurchaseOrder slot="pdf-content" v-else-if="type === 'PurchaseOrderPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierDeliveryNoteReport slot="pdf-content" v-else-if="type === 'SupplierDeliveryNoteReportPdf'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierDeliveryNote slot="pdf-content" v-else-if="type === 'SupplierDeliveryNotePDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierInvoiceReport slot="pdf-content" v-else-if="type === 'SupplierInvoiceReportPdf'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierInvoice slot="pdf-content" v-else-if="type === 'SupplierInvoicePDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierCreditInvoiceReport slot="pdf-content" v-else-if="type === 'SupplierCreditInvoiceReportPdf'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierCreditInvoice slot="pdf-content" v-else-if="type === 'SupplierCreditInvoicePDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintPurchaseReport slot="pdf-content" v-else-if="type === 'PurchaseReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :isFocused="isFocused" :withPrice="withPrice" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierDebtsReport slot="pdf-content" v-else-if="type === 'SupplierDebtsReport'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :isFocused="isFocused" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierPaymentReport slot="pdf-content" v-else-if="type === 'SupplierPaymentReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintSupplierPayment slot="pdf-content" v-else-if="type === 'SupplierPaymentPdf'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintCashFlowReport slot="pdf-content" v-else-if="type === 'CashFlowReportPDF'" @domRendered="domRendered()" class="me-2 mb-1" :disabled="disabled" :modelData="model" :row="row" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList"/>
      <PrintGenerateEvaluationReport slot="pdf-content" v-else-if="type === 'GenerateEvaluationReport'" @domRendered="domRendered()" class="me-2 mb-1"  :modelData="model" :row="row" :payload="payload" :pdfTemplateValueList="pdfTemplateValueList" :showReceipt="showReceipt"/>
    </vue-html2pdf>
    <v-overlay :z-index="999" v-if="isProgressCircular">
      <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
    </v-overlay>
    <!-- Progress bar -->
    <v-dialog
      v-model="progressDialog"
      persistent
      width="300"
    >
      <v-card
        color="accent lighten-3"
        dark
      >
        <v-card-text class="pt-2 pb-2 primary--text">
          {{$t('quotationPdf.message.generatingPdf')}}
          <v-progress-linear
            color="primary"
            class="mb-0 mt-1"
            height="22"
            striped
            v-model="progress"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <WareHouseConfirmationDialog v-if="warehoseConfirmationDialog" :dialog.sync="warehoseConfirmationDialog" />
    <EmailDialog :customerId="model.customer_id" :otherEmail.sync="model.email" :dialog.sync="emailDialog" @sendEmailPdf="sendEmailPdf" :emailArray.sync="emailArray" :type="type"/>
    <CheckingUpdateInvoiceTotal :dialog.sync="checkingUpdateInvoiceTotalDialog" />
    <CheckReceiptTaxInvoicePaymentDialog :dialog.sync="checkingUpdateReceiptInvoiceTotalDialog" />
    <DynamicTemplateDialog :dialog.sync="dynamicTemplateDialogBox" @selectedTemplateForPdf="selectedTemplateForPdf" />
  </div>
</template>
<script>
import PrintOrder from "@/components/generatePDF/PrintOrder";
import PrintDelivery from "@/components/generatePDF/PrintDelivery";
import PrintQuotation from "@/components/generatePDF/PrintQuotation";
import PrintInvoice from "@/components/generatePDF/PrintInvoice";
import PrintReceipt from "@/components/generatePDF/PrintReceipt";
import PrintDeposit from "@/components/generatePDF/PrintDeposit";
import PrintCreditInvoice from "@/components/generatePDF/PrintCreditInvoice";
import PrintProforma from "@/components/generatePDF/PrintProforma";
import PrintDebitReport from "@/components/generatePDF/PrintDebitReport";
import EmailDialog from "@/components/generatePDF/EmailDialog";
import PrintEmployeeReport from "@/components/generatePDF/excelReportPdf/PrintEmployeeReport";
import PrintCustomerReport from "@/components/generatePDF/excelReportPdf/PrintCustomerReport";
import PrintSupplierReport from "@/components/generatePDF/excelReportPdf/PrintSupplierReport";
import PrintProductReport from "@/components/generatePDF/excelReportPdf/PrintProductReport";
import PrintBankReport from "@/components/generatePDF/excelReportPdf/PrintBankReport";
import PrintQuotationReport from "@/components/generatePDF/excelReportPdf/PrintQuotationReport";
import PrintOrderReport from "@/components/generatePDF/excelReportPdf/PrintOrderReport";
import PrintDepositReport from "@/components/generatePDF/excelReportPdf/PrintDepositReport";
import PrintDeliveryReport from "@/components/generatePDF/excelReportPdf/PrintDeliveryReport";
import PrintInvoiceReport from "@/components/generatePDF/excelReportPdf/PrintInvoiceReport";
import PrintReceiptReport from "@/components/generatePDF/excelReportPdf/PrintReceiptReport";
import PrintProformaReport from "@/components/generatePDF/excelReportPdf/PrintProformaReport";
import PrintCreditInvoiceReport from "@/components/generatePDF/excelReportPdf/PrintCreditInvoiceReport";
import PrintSalesReport from "@/components/generatePDF/PrintSalesReport";
import PrintAccountingCardReport from "@/components/generatePDF/PrintAccountingCardReport";
import PrintReceiptTaxInvoiceReport from "@/components/generatePDF/excelReportPdf/PrintReceiptTaxInvoiceReport";
import PrintReceiptTaxInvoice from "@/components/generatePDF/PrintReceiptTaxInvoice";
import PrintTrialBalanceReportPDF from "@/components/generatePDF/PrintTrialBalanceReportPDF";
import PrintReturnCertificateReportPDF from "@/components/generatePDF/excelReportPdf/PrintReturnCertificateReportPDF";
import PrintRetunCertificate from "@/components/generatePDF/PrintRetunCertificate";
import CheckingUpdateInvoiceTotal from '@/components/CheckingUpdateInvoiceTotal';
import CheckReceiptTaxInvoicePaymentDialog from '@/components/CheckReceiptTaxInvoicePaymentDialog';
import WareHouseConfirmationDialog from '@/components/WareHouseConfirmationDialog';
import PrintJournalEntryReport from '@/components/generatePDF/excelReportPdf/PrintJournalEntryReport';
import PrintPurchaseOrderReport from '@/components/generatePDF/excelReportPdf/PrintPurchaseOrderReport';
import PrintPurchaseOrder from '@/components/generatePDF/PrintPurchaseOrder';
import PrintSupplierDeliveryNoteReport from '@/components/generatePDF/excelReportPdf/PrintSupplierDeliveryNoteReport';
import PrintSupplierDeliveryNote from '@/components/generatePDF/PrintSupplierDeliveryNote';
import PrintSupplierInvoiceReport from '@/components/generatePDF/excelReportPdf/PrintSupplierInvoiceReport';
import PrintSupplierInvoice from '@/components/generatePDF/PrintSupplierInvoice';
import PrintSupplierCreditInvoiceReport from '@/components/generatePDF/excelReportPdf/PrintSupplierCreditInvoiceReport';
import PrintSupplierCreditInvoice from '@/components/generatePDF/PrintSupplierCreditInvoice';
import PrintPurchaseReport from '@/components/generatePDF/PrintPurchaseReport';
import PrintSupplierDebtsReport from '@/components/generatePDF/PrintSupplierDebtsReport';
import PrintSupplierPaymentReport from '@/components/generatePDF/excelReportPdf/PrintSupplierPaymentReport';
import PrintSupplierPayment from '@/components/generatePDF/PrintSupplierPayment';
import PrintCashFlowReport from '@/components/generatePDF/PrintCashFlowReport';
import DynamicTemplateDialog from '@/components/DynamicTemplateDialog';
import PrintGenerateEvaluationReport from '@/components/generatePDF/excelReportPdf/PrintGenerateEvaluationReport';
import { mapGetters } from 'vuex';
// import {changedFormData} from '@/utils';
export default {
  name: "HtmlToPdf",
  components: {
    PrintQuotation,
    PrintOrder,
    PrintDelivery,
    PrintInvoice,
    PrintReceipt,
    PrintDeposit,
    PrintCreditInvoice,
    PrintProforma,
    EmailDialog,
    PrintDebitReport,
    PrintEmployeeReport,
    PrintCustomerReport,
    PrintSupplierReport,
    PrintProductReport,
    PrintBankReport,
    PrintQuotationReport,
    PrintOrderReport,
    PrintDepositReport,
    PrintDeliveryReport,
    PrintInvoiceReport,
    PrintReceiptReport,
    PrintProformaReport,
    PrintCreditInvoiceReport,
    PrintSalesReport,
    PrintAccountingCardReport,
    PrintReceiptTaxInvoiceReport,
    PrintReceiptTaxInvoice,
    PrintTrialBalanceReportPDF,
    PrintReturnCertificateReportPDF,
    PrintRetunCertificate,
    CheckingUpdateInvoiceTotal,
    CheckReceiptTaxInvoicePaymentDialog,
    WareHouseConfirmationDialog,
    PrintJournalEntryReport,
    DynamicTemplateDialog,
    PrintPurchaseOrderReport,
    PrintPurchaseOrder,
    PrintSupplierDeliveryNoteReport,
    PrintSupplierDeliveryNote,
    PrintSupplierInvoiceReport,
    PrintSupplierInvoice,
    PrintSupplierCreditInvoiceReport,
    PrintSupplierCreditInvoice,
    PrintPurchaseReport,
    PrintSupplierDebtsReport,
    PrintSupplierPaymentReport,
    PrintSupplierPayment,
    PrintCashFlowReport,
    PrintGenerateEvaluationReport
  },
  data() {
    return {
      progressDialog: false,
      progress: 0,
      preview: false,
      download: false,
      sendEmail: false,
      contentRendered: false,
      emailDialog: false,
      emailArray: [],
      isPDFPreview: false,
      checkingUpdateInvoiceTotalDialog: false,
      checkingUpdateReceiptInvoiceTotalDialog: false,
      isProgressCircular: false,
      warehoseConfirmationDialog: false,
      dynamicTemplateDialogBox: false,
      pdfTemplateValueList: {},
    }
  },
  props:['model', 'disabled', 'type', 'isFocused', 'withPrice', 'payload', 'saveInvoice', 'row', 'showReceipt'],
  computed: {
    fileName () {
      const [year, month, day] = new Date().toISOString().substring(0,10).split('-')
      return this.type === 'DebitReportPDF' || this.type === 'CashFlowReportPDF' || this.type === 'SupplierDebtsReport' || this.type === 'SalesReportPDF' || this.type === 'PurchaseReportPDF' || this.type === 'AccountingCardReportPDF' || this.type === 'BankReportPDF' || this.type === 'ItemReportPDF' || this.type === 'SupplierReportPDF' || this.type === 'CustomerReportPDF' || this.type === 'EmployeeReportPDF' || this.type === 'QuotationReportPDF' || this.type === 'OrderReportPDF' || this.type === 'ProformaReportPDF' || this.type === 'CreditInvoiceReportPDF' || this.type === 'InvoiceReportPDF' || this.type === 'DepositReportPDF' || this.type === 'DeliveryReportPDF' || this.type === 'ReceiptReportPDF' || this.type === 'ReceiptTaxInvoiceReportPDF' || this.type === 'TrialBalanceReportPDF' || this.type === 'ReturnCertificateReportPDF' || this.type === 'JournalEntryReportPDF' || this.type === 'PurchaseOrderReportPDF' || this.type === 'SupplierDeliveryNoteReportPdf' || this.type === 'SupplierInvoiceReportPdf' || this.type === 'SupplierCreditInvoiceReportPdf' || this.type === 'SupplierPaymentReportPDF' || this.type === 'GenerateEvaluationReport' ? `${this.type}_${day}${month}${year}` : `${this.type}_${this.model.id}_${day}${month}${year}`
    },
    isValidForPrint() {
      if (this.type === 'OrderPDF' || this.type === 'DeliveryPDF' || this.type === 'InvoicePDF' || this.type === 'CreditInvoicePDF' || this.type === 'ReceiptTaxInvoicePDF' || this.type === 'RetunCertificatePDF' || this.type === 'PurchaseOrderPDF' || this.type === 'SupplierDeliveryNotePDF' || this.type === "SupplierInvoicePDF" || this.type === 'SupplierCreditInvoicePDF') {
        return this.model.customer && this.model.customer.customer_order_no_compulsory && !this.model.customer_order_no && !this.model.status ? true : false;
      } else {
        return false;
      }
    },
    ...mapGetters({
      accessRight: "accessRight",
      profileById: 'profile/profileById',
      pdfTemplateList: "pdfTemplate/pdfTemplateList",
    })
  },
  async mounted() {
    await this.$store.dispatch('profile/GetCompanyById');
    await this.$store.dispatch('pdfTemplate/GetPdfTemplate');
  },
  methods: {
    async selectedTemplateForPdf(val) {
      this.pdfTemplateValueList = val
      this.generatePdf();
    },
    async generatePdf() {
      this.isPDFPreview = false;
      if (this.type === 'InvoicePDF') {
        this.$store.dispatch('invoice/GetTotalBalanceOfCustomer', this.model.customer_id)
      }
      if (this.type === 'CreditInvoicePDF') {
        const sumAmountPaid = this.model.creditInvoicePayments.reduce((sum, item) => sum + parseFloat(item.amount_paid), 0);
        if (sumAmountPaid > this.model.grand_total) {
          this.checkingUpdateInvoiceTotalDialog = true;
          return
        }
      }
      if (this.type === 'ReceiptTaxInvoicePDF') {
        await this.saveInvoice();
        const sumAmountPaid = this.model.receiptTaxInvoicePayments.reduce((sum, item) => sum + parseFloat(item.amount), 0);
        if (this.model.status === 0 && (sumAmountPaid !== this.model.grand_total)) {
          this.checkingUpdateReceiptInvoiceTotalDialog = true;
          this.download = false;
          this.preview = false;
          return
        }
      }
      if ((this.type === 'DeliveryPDF' || this.type === 'InvoicePDF' || this.type === 'CreditInvoicePDF' || this.type === 'ReceiptTaxInvoicePDF' || this.type === 'RetunCertificatePDF') && !this.model.warehouse && this.profileById.warehouse_inventory_management && this.model.status == 0) {
        this.warehoseConfirmationDialog = true;
      } else {
        this.warehoseConfirmationDialog = false;
        this.download = true;
        this.preview = true;
        this.sendEmail = false;
        this.progressDialog = true;
        setTimeout(() => {
          this.$refs.html2Pdf.generatePdf();
        }, 1000);
      }
    },
    openDialog() {
      if (this.pdfTemplateList && this.pdfTemplateList.length) {
        this.dynamicTemplateDialogBox = true;
      } else {
        this.generatePdf();
      }
    },
    async previewPdf() {
      this.isPDFPreview = true;
      if (this.type === 'InvoicePDF') {
        this.$store.dispatch('invoice/GetTotalBalanceOfCustomer', this.model.customer_id)
      }
      if (this.type === 'ReceiptTaxInvoicePDF') {
        await this.saveInvoice();
        const sumAmountPaid = this.model.receiptTaxInvoicePayments.reduce((sum, item) => sum + parseFloat(item.amount), 0);
        if (this.model.status === 0 && (sumAmountPaid !== this.model.grand_total)) {
          this.checkingUpdateReceiptInvoiceTotalDialog = true;
          this.preview = false;
          return
        }
      }
      this.download = false;
      this.preview = true;
      this.sendEmail = false;
      this.progressDialog = true;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 1000);
    },
    sendEmailPdf() {
      this.download = false;
      this.preview = false;
      this.sendEmail = true;
      this.progressDialog = false;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 1000);
    },
    // eslint-disable-next-line
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      let self = this;
      if (this.sendEmail) {
        await html2pdf().from(pdfContent).toPdf().output('blob').then(async function (pdfAsString) {
          const file = new File([pdfAsString], self.fileName+'.pdf', { type: 'application/pdf' })
          let formData = new FormData();
          formData.append("filename", file);
          formData.append('subject', self.type === 'DebitReportPDF' ? `${self.type}` : `${self.type}_${self.model.id}`)
          formData.append("email", JSON.stringify(self.emailArray));
          await self.$store.dispatch('media/SendPdfByEmail', formData);
          self.emailDialog = false;
        });
      }
    },
    async onProgress(progress) {
      this.progress = progress;
      if(progress == 100) {
        this.progressDialog = false;
        this.progress = 0;
        //  set status closed on print proforma invoice
        if (this.type === 'ProformaInvoicePDF' && this.model.status == 0 && this.download) {
          this.isProgressCircular = true;
          await this.$store.dispatch('proforma/EditProforma', {proforma_id: this.model.id, proforma: {status: 1}}).catch((e) => {
            console.log(e)
          });                       // main_number: 37 (Proforma Invoice)
          await this.$store.dispatch('proforma/GetProformaById', this.model.id);
          this.isProgressCircular = false;
        }
        //  set status closed on print delivery
        if (this.type === 'DeliveryPDF' && this.model.status == 0 && this.download) {
          this.isProgressCircular = true;
          await this.$store.dispatch('delivery/EditDelivery', {delivery_id: this.model.id, delivery: {status: 1, main_number: 30, order_model_number: 15, quotation_model_number: 14}}).catch((e) => {
            console.log(e)
          });
          await this.$store.dispatch('delivery/GetDeliveryById', this.model.id);
          this.isProgressCircular = false;
        }
        //set status closed on print return certificate
        if (this.type === 'RetunCertificatePDF' && this.model.status == 0 && this.download) {
          this.isProgressCircular = true;
          await this.$store.dispatch('returnCertificate/EditReturnCertificate', {tax_id: this.model.id, delivery: {status: 1, main_number: 118}}).catch((e) => {
            console.log(e)
          });
          await this.$store.dispatch('returnCertificate/GetReturnCertificateById', this.model.id);
          this.isProgressCircular = false;
        }
        //  set status closed on print invoice
        if (this.type === 'InvoicePDF' && this.model.status == 0 && this.download) {
          this.isProgressCircular = true;
          await this.$store.dispatch('invoice/EditInvoice', {invoice_id: this.model.id, invoice: {status: 1, main_number: 18, order_model_number: 15, deliveryNote_model_number: 30, quotation_model_number: 14}}).catch((e) => {
            console.log(e)
          });
          await this.$store.dispatch('invoice/GetInvoiceById', this.model.id);
          this.isProgressCircular = false;
        }
        //set print status on print receipt tax invoice
        if (this.type === 'ReceiptTaxInvoicePDF' && this.model.status == 0 && this.download) {
          this.isProgressCircular = true;
          await this.$store.dispatch('receiptTaxInvoice/EditReceiptTaxInvoice', {tax_id: this.model.id, invoice: {status: 1, main_number: 20, order_model_number: 15, deliveryNote_model_number: 30, quotation_model_number: 14, paymentData: this.model.receiptTaxInvoicePayments}}).catch((e) => {
            console.log(e)
          });
          await this.$store.dispatch('receiptTaxInvoice/GetReceiptTaxInvoiceById', this.model.id);
          this.isProgressCircular = false;
        }
        //  set print status on print receipt
        if (this.type === 'ReceiptPDF' && this.download && this.model.status == 0) {
          this.isProgressCircular = true;
          await this.$store.dispatch('receipt/EditReceipt', {receipt_id: this.model.id, receipt: {is_printed: 1, status: 1}}).catch((e) => {
            console.log(e)
          });
          await this.$store.dispatch('receipt/GetReceiptById', this.model.id);
          this.isProgressCircular = false;
        }
        //  set print status on print receipt
        if (this.type === 'CreditInvoicePDF' && this.model.status == 0 && this.download) {
          this.isProgressCircular = true;
          await this.$store.dispatch('creditInvoice/EditCreditInvoice', {credit_invoice_id: this.model.id, creditInvoice: {status: 1}}).catch((e) => {
            console.log(e)
          });
          await this.$store.dispatch('creditInvoice/GetCreditInvoiceById', this.model.id);
          this.isProgressCircular = false;
        }
      }
    },
    domRendered() {
      this.contentRendered = true;
    },
  }
}
</script>