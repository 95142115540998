<template>
  <div>
    <template>
      <v-app>
        <div v-for="index in printPage" :key="index" class="html2pdf__page-break">
          <div style="height: 1050px">
          <div
            class="mx-auto"
            style="min-height: 200px"
          >
            <v-card-title class="py-1" v-if="!Object.keys(pdfTemplateValueList).length">
              <v-row dense no-gutters style="width: 100%">
                <v-col cols="12" class="text-center primary--text" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center primary--text" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="font-weight-regular mt-n2">Test Software By BINA</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('invoicePdf.companyAddress') }}</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('invoicePdf.tel') }}: 972-3-7361150 {{ $t('invoicePdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12" class="text-center my-2">
                  <h5 class="text-decoration-underline mt-n2 primary--text">
                    {{ $t('generalEvaluation.generalEvaluation') }}
                  </h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title class="py-1" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
              <v-row dense no-gutters style="width: 100%">
                <v-col cols="12" class="text-center" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="mt-n2">Test Software By BINA</h5>
                  <h5 class="mt-n2">{{ $t('invoicePdf.companyAddress') }}</h5>
                  <h5 class="mt-n2">{{ $t('invoicePdf.tel') }}: 972-3-7361150 {{ $t('invoicePdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12" class="text-center my-2">
                  <h5 class="mt-n2">
                    {{ $t('generalEvaluation.generalEvaluation') }}
                  </h5>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          <div
            class="mx-auto"
            flat
            ref="itemContent"
          >
            <v-card-title class="py-1">
              <v-row dense>
                <v-col cols="12" class="text-body-2 py-0 text-end">
                  {{ $t('invoicePdf.page') }} {{index}} {{ $t('invoicePdf.of') }} {{printPage}}
                </v-col>
                <v-col cols="6" class="product">
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-title class="pa-0 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("generalEvaluation.customerObligations") }}
                      </h6>
                    </v-card-title>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.debts") }}</td>
                            <td>{{ model && model.customer_obligation_final_data ? numberWithCommas(model.customer_obligation_final_data.debt) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.futureChecks") }}</td>
                            <td>{{ model && model.customer_obligation_final_data ? numberWithCommas(model.customer_obligation_final_data.future_check) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.totalCustomers") }}</td>
                            <td>{{ model && model.customer_obligation_final_data ? numberWithCommas(model.customer_obligation_final_data.total_customer) : 0 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-title class="pa-0 primary--text">
                      <v-icon color="primary">mdi-account-badge-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("generalEvaluation.supplierObligations") }}
                      </h6>
                    </v-card-title>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.debts") }}</td>
                            <td>{{ model && model.supplier_obligation_data ? numberWithCommas(model.supplier_obligation_data.debt) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.supplierChecks") }}</td>
                            <td>{{ model && model.supplier_obligation_data ? numberWithCommas(model.supplier_obligation_data.supplier_check) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.totalSuppliers") }}</td>
                            <td>{{ model && model.supplier_obligation_data ? numberWithCommas(model.supplier_obligation_data.total_supplier) : 0 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.totalObligations") }}</td>
                            <td>{{ model && model.total_obligation_data ? numberWithCommas(model.total_obligation_data.total_obligations) : 0 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6" class="product">
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-title class="pa-0 primary--text">
                      <v-icon color="primary">mdi-book-open-page-variant-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("generalEvaluation.incomeForecast") }}
                      </h6>
                    </v-card-title>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.deliveryNotes") }}</td>
                            <td>{{ model && model.income_forecast ? numberWithCommas(model.income_forecast.delivery_note) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.agentDeliveryNotes") }}</td>
                            <td>{{ model && model.income_forecast ? numberWithCommas(model.income_forecast.agent_delivery_note) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.proformas") }}</td>
                            <td>{{ model && model.income_forecast ? numberWithCommas(model.income_forecast.proforma_invoice) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.orderInProduction") }}</td>
                            <td>{{ model && model.income_forecast ? numberWithCommas(model.income_forecast.order_sum) : 0 }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.totalForecast") }}</td>
                            <td>{{ model && model.income_forecast ? numberWithCommas(model.income_forecast.total_forcast) : 0 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-title class="pa-0 primary--text">
                      <v-icon color="primary">mdi-cart</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("generalEvaluation.expensesForecast") }}
                      </h6>
                    </v-card-title>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.supplierOrders") }}</td>
                            <td>{{ model && model.expence_forecast ? numberWithCommas(model.expence_forecast.supplier_order) : 0 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td class="font-weight-medium">{{ $t("generalEvaluation.totalDifference") }}</td>
                            <td>{{ model && model.total_difference ? numberWithCommas(model.total_difference.total_difference) : 0 }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="7" class="product">
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense style="width: 100%;">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.month') }}</th>
                              <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.invoices') }}</th>
                              <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.accumulated') }}</th>
                              <th :class="locale === 'he' ? 'text-right' : 'text-left'">%</th>
                            </tr>
                          </thead>
                          <tbody v-if="model && model.invoice">
                            <tr v-for="(data, index) in model.invoice.invoiceRecords" :key="index">
                              <td class="font-weight-medium">{{ $t(`generalEvaluation.monthList.${data.month}`) }}</td>
                              <td>{{ numberWithCommas(data.invoice) }}</td>
                              <td>{{ numberWithCommas(data.accumulated) }}</td>
                              <td>{{ numberWithCommasFloat(data.percentage) }}%</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="5" class="product">
                  <v-card class="mx-auto mb-2" flat>
                    <v-card-text class="pa-0 px-2">
                      <v-simple-table dense style="width: 100%;">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ showReceipt ? $t("generalEvaluation.receipts") : $t("generalEvaluation.purchases") }}</th>
                              <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.accumulated') }}</th>
                              <th :class="locale === 'he' ? 'text-right' : 'text-left'">%</th>
                            </tr>
                          </thead>
                          <tbody v-if="model && model.receipt && showReceipt">
                            <tr v-for="(data, index) in model.receipt.receiptRecords" :key="index">
                              <td>{{ numberWithCommas(data.receipt) }}</td>
                              <td>{{ numberWithCommas(data.receiptAccumulated) }}</td>
                              <td>{{ numberWithCommasFloat(data.percentage) }}%</td>
                            </tr>
                          </tbody>
                          <tbody v-else-if="model && model.purchase && !showReceipt">
                            <tr v-for="(data, index) in model.purchase.purchaseRecords" :key="index">
                              <td>{{ numberWithCommas(data.purchase) }}</td>
                              <td>{{ numberWithCommas(data.purchaseAccumulated) }}</td>
                              <td>{{ numberWithCommasFloat(data.percentage) }}%</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title v-if="index == printPage" class="py-0">
              <v-row dense no-gutters class="text-end">
                <v-col cols="8">
                </v-col>
                <v-col v-if="accessRight.includes('financial')" cols="4" class="text-body-2">
                  <v-row v-if="index === printPage" class="text-end">
                    <v-col cols="12" class="text-body-1" v-if="!Object.keys(pdfTemplateValueList).length">
                      {{$t('invoicePdf.withRegards')}}<br>
                      {{model.employee ? model.employee.name : user.name}}<br>
                      <span class="text-body-2">{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                    <v-col cols="12" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
                      {{$t('invoicePdf.withRegards')}}<br>
                      {{model.employee ? model.employee.name : user.name}}<br>
                      <span>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          </div>
          <div class="pb-0 px-4 pt-1" color="white">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-img width="100%" eager :src="bottom" />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-app>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';
export default {
  name: "PrintGenerateEvaluationReport",
  props:['modelData', 'payload', 'pdfTemplateValueList', 'showReceipt'],
  data() {
    return{
      company: '',
      printPage: 1,
      rowCount: 0,
      lastIndex: [],
      topLogoUrl: null,
      bottomLogoUrl: null,
      invoiceItemsObject: []
    }
  },
  async mounted() {
    this.company = await this.$store.dispatch('profile/GetCompanyById');
    if(this.company.topLogo) {
      this.getBase64Image(this.company.topLogo.file_path, 'topLogo');
    }
    if(this.company.bottomLogo) {
      this.getBase64Image(this.company.bottomLogo.file_path, 'bottomLogo');
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
  watch: {
    modelData: {
      async handler(){
        this.totalRow;
      },
      deep: true
    }
  },
  computed: {
    model() {
      return this.modelData;
    },
    bottom() {
      return this.bottomLogoUrl;
    },
    top() {
      return this.topLogoUrl;
    },
    ...mapGetters({
      locale: 'locale',
      accessRight: 'accessRight',
      profileById: 'profile/profileById',
      user: 'user',
    }),
    // totalRow() {
    //   let rowCount = 0;
    //   /* eslint-disable */
    //   this.printPage = 1;
    //   let beforeRowCount = rowCount;
    //   if(this.model) {
    //     /* eslint-disable */
    //     this.invoiceItemsObject = [];
    //     this.lastIndex = [];
    //     this.model.forEach(mainItem => {
    //       this.invoiceItemsObject.push({customerData: mainItem});
    //     })
    //     /* eslint-enable */
    //     this.invoiceItemsObject.forEach((element,index) => {
    //         let allItemCustomerNameCount = 0;
    //         if(index == this.invoiceItemsObject.length - 1) {
    //           rowCount = Number(rowCount) + 5;
    //         }
    //         if (element.customerData) {
    //           let itemCustomerNameCount = 0;
    //           if(element.customerData.customer_name) {
    //             itemCustomerNameCount = element.customerData.customer_name.match(/.{1,20}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(element.customerData.customer_name.match(/.{1,20}/g).length)) : Number(itemCustomerNameCount) + 1;
    //             allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
    //             rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
    //           } else {
    //             rowCount = Number(rowCount) + 1;
    //           }
    //         } else {
    //           let itemCustomerNameCount = 0;
    //           if(element.customer_name) {
    //             itemCustomerNameCount = element.customer_name.match(/.{1,19}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(element.customer_name.match(/.{1,19}/g).length)) : Number(itemCustomerNameCount) + 1;
    //             allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
    //           }
    //           rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
    //         }
    //         let tempCount = 0;
    //         if(tempCount < rowCount && rowCount >= 20) {
    //           tempCount = rowCount;
    //           this.lastIndex.push(this.printPage == 1 ? index+1 : index+1);
    //           this.printPage += 1;
    //           rowCount = Number(allItemCustomerNameCount) + beforeRowCount;
    //         }
    //       });
    //   }
    //   return rowCount;
    // }
  },
  methods: {
    formatDateDDMMYYYY,
    parseDateYYYYMMDD,
    getBase64Image(imgUrl, type) {
      let img = new Image();
      let self = this;
      // onload fires when the image is fully loadded, and has width and height
      img.onload = function(){
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png")
        type === 'bottomLogo' ? self.bottomLogoUrl = dataURL : self.topLogoUrl = dataURL; // the base64 string
      };
      // set attributes and src 
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = imgUrl + '?' + new Date().toLocaleString('sv-SE');
    },
    getNumbers:function(start,stop){
      return new Array(stop-start).fill(start).map((n,i)=>n+i);
    },
    numberWithCommas(x) {
      return x ? parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0);
    },
    numberWithCommasFloat(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
  }
}
</script>
<style scoped>
.product table {
  border-collapse: collapse;
}
.product table, .product table td {
  border: 0px solid black;
}
.product table {
  border-width: 1px 1px 1px 1px;
}
.product table td {
  border-width: 1px 0 0 1px;
  border-left: none;
  border-right: none;
}
.product th {
  border: 0px solid black;
  border-width: 0 0 0 1px;
  border-left: none;
  border-right: none;
}
#footer .v-image {
  width: 110px;
  right: 0;
  position: absolute;
  left: 0;
}
</style>
