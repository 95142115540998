<template>
  <v-bottom-sheet
    v-model="dialog"
    persistent
    max-width="70%"
    inset
    overlay-opacity="0.5"
  >
    <v-card>
      <v-card-title class="pa-2">
        <v-spacer />
        <v-btn
          color="primary"
          small
          :disabled="!valid || (accessRight.length < 2 && accessRight.includes('show'))"
          :loading="loading"
          @click="saveRemarks"
        >
          {{$t('remarks.save')}}
        </v-btn>
        <v-btn
          icon
          @click="$emit('update:dialog', false);"
        >
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-h5 px-4">
        <v-form
          ref="formRemarks"
          v-model="valid"
          lazy-validation
          :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
        >
          <Alert :alert.sync="alert" :message="message" />
          <v-row>
            <v-col cols="12">
              <v-card
                class="mx-auto"
                flat
              >
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-notebook-edit-outline</v-icon>
                  <h6 class="ms-2 align-self-center font-weight-regular">{{$t('remarks.remarksList')}}</h6>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row :dense="true">
                    <v-col cols="12">
                      <v-simple-table
                        dense
                        class="custom-table elevation-1 item-table"
                        fixed-header
                        height="350"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th :style="{width: '60px'}">
                                {{ $t('remarks.date') }}
                              </th>
                              <th :style="{width: '40px'}">
                                {{ $t('remarks.time') }}
                              </th>
                              <th :style="{width: '70px'}">
                                {{ $t('remarks.employee') }}
                              </th>
                              <th class="text-center" :style="{width: '250px'}">
                                {{ $t('remarks.remarks') }}
                              </th>
                              <th class="text-center" :style="{width: '50px'}">
                                {{ $t('remarks.action') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="remarksItems.length <= 0">
                              <td colspan="8" class="text-center">{{$t('common.noDataFound')}}</td>
                            </tr>
                            <tr v-else v-for="(item, index) in remarksItems" style="vertical-align: bottom" :key="index" :class="index+1 == remarksItems.length ? 'newRow' : ''">
                              <td>
                                <span>
                                  {{profileById.date_format ? formatDateDDMMYYYY(item.date) : parseDateYYYYMMDD(item.date)}}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {{ item.id ? item.time.substring(11,16) : item.time.substr(0,5) }}
                                </span>
                              </td>
                              <td :style="{wordBreak: 'break-word'}">
                                {{item.employee_name}}
                              </td>
                              <td>
                                <v-textarea
                                  class="date-input"
                                  :readonly="item.id && item.user_id !== $store.state.user.user.id"
                                  auto-grow
                                  rows="1"
                                  dense
                                  hide-details="auto"
                                  v-model.trim="item.remarks"
                                />
                              </td>
                              <td class="text-center">
                                  <v-row>
                                    <v-col cols="6" class="text-end">
                                      <v-btn
                                        icon
                                        color="primary"
                                        small
                                        @click="addRow"
                                        :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                                      >
                                        <v-icon color="primary">
                                          mdi-plus
                                        </v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="6" class="text-start">
                                        <v-btn
                                          v-if="remarksItems.length !== 1 && !item.id"
                                          icon
                                          color="error"
                                          small
                                          @click="deleteRow(index)"
                                          :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                  </v-row>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { mapGetters } from 'vuex';
import Alert from "@/components/Alert"
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';

export default {
  name: "RemarksDialog",
  props: ["dialog", "status", "docId", "docType"],
  components: {
    Alert
  },
  data () {
    return {
      alert: false,
      loading: false,
      valid: false,
      model: {},
      message: '',
      remarksItems: [],
    }
  },
  computed: {
    remarksValid() {
      return [
        v => !!v || this.$t('formRules.titleRequired'),
        v => !v || !/^\s*$/.test(v) || this.$t('formRules.titleRequired'),
        v => !v || (v && v.length <= 800) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('remarks.remarks'), type: this.$t('common.less'), size: 800 }),
      ];
    },
    isFieldReadable () {
      return !this.status ? false : true;
    },
    ...mapGetters({
      remarksList: 'remarks/remarksList',
      profileById: 'profile/profileById',
      accessRight: "accessRight"
    })
  },
  watch: {
    async dialog(val) {
      if (!val) {
        this.alert = false;
        this.loading = false;
        this.valid = false;
        this.message = '';
        this.remarksItems = [];
        this.$refs.formRemarks.reset();
      } else {
        this.remarksItems = [];
        await this.$store.dispatch('remarks/GetRemarks', {where: 'document_type|'+this.docType, where_and: 'document_id|'+this.docId})
      }
    },
    remarksList: {
      handler: function() {
        this.remarksItems = [];
        if (this.remarksList.length > 0) {
          this.remarksList.forEach(element => {
            this.remarksItems.push(Object.assign({}, {...element}));
          })
        }
        this.remarksItems.push(
          {
            date: new Date().toLocaleDateString('sv-SE'),
            time: new Date().toLocaleTimeString('sv-SE',{timeStyle: 'short'}),
            employee_name: this.$store.state.user.user.name,
            remarks: null
          }
        );
      },
      deep: true,
    }
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    parseDateYYYYMMDD: parseDateYYYYMMDD, 
    openStatus(){
      this.$emit('update:dialog', false);
    },
    closeStatus(){
      this.$emit('update:dialog', false);
    },
    addRow() {
      this.remarksItems.push({
        date: new Date().toLocaleDateString('sv-SE'),
        time: new Date().toLocaleTimeString('sv-SE',{timeStyle: 'short'}),
        employee_name: this.$store.state.user.user.name,
        remarks: null
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(".item-table div.v-data-table__wrapper")
      this.$vuetify.goTo(newRow, {container: wrapper});
    },
    deleteRow(index) {
      this.remarksItems.splice(index,1);
    },
    async saveRemarks() {
      if(this.$refs.formRemarks.validate()){
        this.loading = true;
        let saveRemarksData = {
          updateRemarks: [],
          addRemarks: []
        };

        this.remarksItems.forEach(element => {
          if (element.id) {
            if (element.user_id == this.$store.state.user.user.id) {
              saveRemarksData.updateRemarks.push({
                id: element.id,
                remarks: element.remarks
              })
            }
          } else {
            if (element.remarks) {
              saveRemarksData.addRemarks.push({
                document_id: this.docId,
                year: new Date().getFullYear(),
                document_type: this.docType,
                date: element.date,
                time: element.time,
                employee_name: element.employee_name,
                remarks: element.remarks,
                user_id: this.$store.state.user.user.id,
              })
            }
          }
        });

        if(saveRemarksData.updateRemarks.length > 0 || saveRemarksData.addRemarks.length > 0) {
          let data = await this.$store.dispatch('remarks/SetRemarks', saveRemarksData).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.formRemarks.reset();
            this.loading = false;
            this.remarksItems = [];
            await this.$store.dispatch('remarks/GetRemarks', {where: 'document_type|'+this.docType, where_and: 'document_id|'+this.docId})
          }
        } else {
          this.message = "formRules.remarksItemAlert";
          this.alert = true;
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    }
  }
}
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>