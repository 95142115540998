var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[(_vm.listSkeleton)?_c('ListSkeleton'):(_vm.activityLogList.data)?_c('v-card-text',{staticClass:"text-h5",class:_vm.docType ? 'px-2 pt-0' : 'pa-0'},[(_vm.docType)?_c('v-card-text',{staticClass:"pa-0 text-end"},[_c('v-icon',{staticClass:"mt-2 primary--text",on:{"click":function($event){return _vm.$emit('update:actDialog', false)}}},[_vm._v("mdi-close")])],1):_vm._e(),_c('v-data-table',{staticClass:"custom-table mb-0 ma-2 row-pointer",staticStyle:{"word-break":"break-word"},attrs:{"dense":true,"headers":_vm.headers,"items-per-page":_vm.limit,"items":_vm.activityLogList.data,"server-items-length":_vm.activityLogList.meta.total,"page":_vm.page,"options":_vm.pagination,"item-class":"py-4","footer-props":{
          'items-per-page-options': [10, 20, 30, 40, 50],
        },"search":_vm.search,"sort-by":_vm.sortByValue,"sort-desc":_vm.sortDescValue},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.pagination=$event},"dblclick:row":_vm.onRowDoubleClick,"update:sortBy":function($event){_vm.sortByValue=$event},"update:sort-by":[function($event){_vm.sortByValue=$event},function (val) { return _vm.buildUrl(val, 'sortKey'); }],"update:sortDesc":function($event){_vm.sortDescValue=$event},"update:sort-desc":[function($event){_vm.sortDescValue=$event},function (val) { return _vm.buildUrl(val, 'sortDec'); }]},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.profileById.date_format ? new Date(item.updated_at).toLocaleString("sv-SE") : _vm.formatDateDDMMYYYY( new Date(item.updated_at) .toLocaleString("sv-SE") .substring(0, 10) ) + new Date(item.updated_at) .toLocaleString("sv-SE") .substring(10)))])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"text-body-2 primary--text mb-2"},[_c('v-row',{staticClass:"my-1",attrs:{"dense":true}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{attrs:{"dense":true}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-menu',{ref:"invoiceEndDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.model.invoice_end_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, "invoice_end_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.model, "invoice_end_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"me-2",attrs:{"hide-details":"auto","dense":"","label":_vm.$t('activityLog.from'),"rules":_vm.profileById.date_format ? _vm.dateValid : _vm.dateValid2},model:{value:(_vm.formattedToDate),callback:function ($$v) {_vm.formattedToDate=$$v},expression:"formattedToDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.invoiceEndDateMenu),callback:function ($$v) {_vm.invoiceEndDateMenu=$$v},expression:"invoiceEndDateMenu"}},[_c('v-date-picker',{on:{"change":function($event){return _vm.$refs.invoiceEndDateMenu.save(
                            _vm.model.invoice_end_date
                          )}},model:{value:(_vm.model.invoice_end_date),callback:function ($$v) {_vm.$set(_vm.model, "invoice_end_date", $$v)},expression:"model.invoice_end_date"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){_vm.$refs.invoiceEndDateMenu.save(_vm.todayDate())}}},[_vm._v(_vm._s(_vm.$t("route.today")))])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{attrs:{"dense":true}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-menu',{ref:"invoiceStartDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.model.invoice_start_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, "invoice_start_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.model, "invoice_start_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"me-2",attrs:{"hide-details":"auto","dense":"","label":_vm.$t('activityLog.to'),"rules":_vm.profileById.date_format ? _vm.dateValid2 : _vm.dateValid},model:{value:(_vm.formattedFromDate),callback:function ($$v) {_vm.formattedFromDate=$$v},expression:"formattedFromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.invoiceStartDateMenu),callback:function ($$v) {_vm.invoiceStartDateMenu=$$v},expression:"invoiceStartDateMenu"}},[_c('v-date-picker',{on:{"change":function($event){return _vm.$refs.invoiceStartDateMenu.save(
                            _vm.model.invoice_start_date
                          )}},model:{value:(_vm.model.invoice_start_date),callback:function ($$v) {_vm.$set(_vm.model, "invoice_start_date", $$v)},expression:"model.invoice_start_date"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){_vm.$refs.invoiceStartDateMenu.save(_vm.todayDate())}}},[_vm._v(_vm._s(_vm.$t("route.today")))])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{staticClass:"text-end d-flex",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"full-width":false,"dense":true,"hide-details":"auto","append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":""},on:{"input":_vm.buildUrl},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider')]},proxy:true},{key:"footer.prepend",fn:function(){return [_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12"}},[_c('span',{staticClass:"pl-3 pt-2"},[_vm._v(_vm._s(_vm.$t("common.numberOfTotalRows"))+" "+_vm._s(_vm.activityLogList.meta.total))])])],1)]},proxy:true},{key:"footer.page-text",fn:function(){return [_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"mx-auto mr-16",attrs:{"cols":"4"}},[_c('v-container',{staticClass:"w-100"},[_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.activityLogList.meta.last_page},on:{"input":_vm.buildUrl},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)]},proxy:true}],null,true)})],1):_c('v-card-text',{staticClass:"text-caption text-center"},[_vm._v(" "+_vm._s(_vm.$t("common.noDataFound")))])],1),_c('ActivityLogModal',{attrs:{"dialog":_vm.activityLogDialog,"selectedRow":_vm.selectedActivityLogRow,"resetRow":_vm.resetRow},on:{"update:dialog":function($event){_vm.activityLogDialog=$event},"update:selectedRow":function($event){_vm.selectedActivityLogRow=$event},"update:selected-row":function($event){_vm.selectedActivityLogRow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }