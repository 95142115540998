<template>
  <div>
    <v-card
      class="mx-auto"
      outlined
    >
      <ListSkeleton v-if="listSkeleton"/>
      <v-card-text class="text-h5" :class="type ? 'px-2 pt-0' : 'pa-0'" v-else-if="customerList.data">
        <slot name="closeIcon"></slot>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          :items="customerList.data"
          :items-per-page="limit"
          :page.sync="page"
          :server-items-length="customerList.meta.total"
          style="word-break: break-word"
          :options.sync="pagination"
          item-class="py-4"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          :search="search"
          @dblclick:row="doubleClick"
          :sort-by.sync="sortByValue"
          :sort-desc.sync="sortDescValue"
          @update:sort-by="(val) => buildUrl(val, 'sortKey')"
          @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
        >
          <template
            v-slot:top
          >
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="6">
                </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    :autofocus="type ? true : false"
                    hide-details="auto"
                    v-model="search"
                    @input="buildUrl"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    hide-details="auto"
                    dense
                    v-model="selectedGroup"
                    :items="groupValue"
                    item-text="description"
                    item-value="id"
                    clearable
                    flat
                    @input="buildUrl"
                    return-object
                    single-line
                    :label="$t('customer.group')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{customerList.meta.total}}</span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    @input="buildUrl"
                    :length="customerList.meta.last_page"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import {getAuxiliaryZtableValueByName} from '@/utils';

export default {
  name: 'CustomerListTable',
  props: ['listSkeleton', 'type', 'sortByName'],
  components: {
    ListSkeleton,
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      groupValue: [],
      selectedGroup: {
        id: Number(this.$route.query.groupId) || null,
      },
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || 'name_1'],
        sortDesc: this.$route.query.order_by ? this.$route.query.sortDec ? [true] : [false] : this.$route.query.sortDec ? [true] : [false]
      },
      search: this.$route.query.search || "",
    }
  },
  computed: {
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
        group_id: this.selectedGroup?.id,
      };
    },
    ...mapGetters({
      customerList: 'customer/customerList',
      accessRight: 'accessRight'
    }),
    headers() {
      return [
        {
          text: this.$t('roles.id'),
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: this.$t('customer.customerName'), value: "name_1" },
        { text: this.$t('customer.group'), value: "group.description", sortable: false },
      ]
    }
  },
  watch: {
    type: {
      handler() {
        this.search = '';
        this.page = 1;
        this.limit = 10;
        this.selectedGroup = null;
      },
      deep: true
    },
    params: {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.paginate();
      },
      deep: true
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = Number(this.$route.query.page) || 1
          
          this.sortByValue = this.$route.query.order_by || null

          this.sortDescValue = this.$route.query.sortDec ? true : null
          
          this.selectedGroup = {
            id: Number(this.$route.query.groupId) || null,
          }

          this.search = this.$route.query.search || ""
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.$emit('update:listSkeleton', true);
    this.$store.commit("customer/SET_CUSTOMER_QUERY", this.$route.query);
    if(Object.keys(this.$route.query).length){
      this.paginate(false);
    } else {
      this.pagination.page = 1
      this.pagination.itemsPerPage = 10
      this.pagination.sortBy = ['name_1']
      this.pagination.sortDesc = [false]
    }
    let tableNameValue = await getAuxiliaryZtableValueByName(2);
    if(Object.keys(tableNameValue).length > 0) {
      this.groupValue = tableNameValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
    }
  },
  methods: {
    async paginate() {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
        group_id
      } = this.params;
      await this.$store.dispatch('customer/GetCustomer', {page: page, limit: itemsPerPage,  where: group_id ? 'group_id|'+group_id : group_id, where_like: this.search ? sortBy[0] ? sortBy[0]+'|'+this.search : sortBy[1] ? sortBy[1]+'|'+this.search : 'id,name_1|'+ this.search : 'id,name_1|', order_by: sortBy?.[0] ? sortBy?.[0]+',id|'+(sortDesc?.[0] ? 'desc' : 'asc') +',desc' : 'name_1,id|'+(sortDesc?.[0] ? 'desc' : 'asc') +',desc' }).then(() => this.$emit('update:listSkeleton', false));
    },
    buildUrl(val) {
      if (!this.type) {
        let obj = {};
        if (typeof val === 'string' || typeof val === 'object') {
          this.page = 1;
          obj.page = 1;
          this.params.page = 1;
        }
        obj.search = this.search;
        obj.page = this.page;
        obj.groupId = this.selectedGroup?.id;
        obj.order_by = this.sortByValue;
        obj.sortDec = this.sortDescValue;
        obj = Object.entries(obj).reduce((acc, [key, val]) => {
          if (key === "groupId" && val === null) return acc;
          else if (key === "page" && val === 1) return acc;
          else if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.push({
          name: "Customer",
          query: {
            ...obj,
          },
        }).catch(()=>{});
        this.$store.commit("customer/SET_CUSTOMER_QUERY", obj);
      }
    },
    doubleClick(event, {item}) {
      !this.type ? this.accessRight.includes('edit') || this.accessRight.includes('show') ? this.$emit('redirectOnEditPage', item) : '' : this.$emit('selectQuotationCustomer', item);
    },
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>