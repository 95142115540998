<template>
  <div>
    <v-card class="mx-auto" outlined>
      <ListSkeleton v-if="listSkeleton" />
      <v-card-text
        class="text-h5"
        :class="docType ? 'px-2 pt-0' : 'pa-0'"
        v-else-if="activityLogList.data"
      >
        <v-card-text class="pa-0 text-end" v-if="docType">
          <v-icon
            class="mt-2 primary--text"
            @click="$emit('update:actDialog', false)"
            >mdi-close</v-icon
          >
        </v-card-text>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          :items-per-page="limit"
          :items="activityLogList.data"
          :server-items-length="activityLogList.meta.total"
          style="word-break: break-word"
          :page.sync="page"
          :options.sync="pagination"
          item-class="py-4"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
          :search="search"
          @dblclick:row="onRowDoubleClick"
          :sort-by.sync="sortByValue"
          :sort-desc.sync="sortDescValue"
          @update:sort-by="(val) => buildUrl(val, 'sortKey')"
          @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
        >
          <template v-slot:[`item.updated_at`]="{ item }">
            <span>{{
              profileById.date_format
                ? new Date(item.updated_at).toLocaleString("sv-SE")
                : formatDateDDMMYYYY(
                    new Date(item.updated_at)
                      .toLocaleString("sv-SE")
                      .substring(0, 10)
                  ) +
                  new Date(item.updated_at)
                    .toLocaleString("sv-SE")
                    .substring(10)
            }}</span>
          </template>
          <template v-slot:top>
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true" class="my-1">
                <v-col cols="2">
                  <v-row :dense="true">
                    <v-col cols="12" class="d-flex">
                      <v-menu
                        ref="invoiceEndDateMenu"
                        v-model="invoiceEndDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="model.invoice_end_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            class="me-2"
                            v-model="formattedToDate"
                            :label="$t('activityLog.from')"
                            v-bind="attrs"
                            :rules="
                              profileById.date_format ? dateValid : dateValid2
                            "
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="model.invoice_end_date"
                          @change="
                            $refs.invoiceEndDateMenu.save(
                              model.invoice_end_date
                            )
                          "
                        >
                          <v-btn
                            small
                            class="primary"
                            @click="$refs.invoiceEndDateMenu.save(todayDate())"
                            >{{ $t("route.today") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row :dense="true">
                    <v-col cols="12" class="d-flex">
                      <v-menu
                        ref="invoiceStartDateMenu"
                        v-model="invoiceStartDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="model.invoice_start_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            class="me-2"
                            v-model="formattedFromDate"
                            :label="$t('activityLog.to')"
                            v-bind="attrs"
                            :rules="
                              profileById.date_format ? dateValid2 : dateValid
                            "
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="model.invoice_start_date"
                          @change="
                            $refs.invoiceStartDateMenu.save(
                              model.invoice_start_date
                            )
                          "
                        >
                          <v-btn
                            small
                            class="primary"
                            @click="
                              $refs.invoiceStartDateMenu.save(todayDate())
                            "
                            >{{ $t("route.today") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5"> </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    v-model="search"
                    @input="buildUrl"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2"
                  >{{ $t("common.numberOfTotalRows") }}
                  {{ activityLogList.meta.total }}</span
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    @input="buildUrl"
                    :length="activityLogList.meta.last_page"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else>
        {{ $t("common.noDataFound") }}</v-card-text
      >
    </v-card>
    <ActivityLogModal
      :dialog.sync="activityLogDialog"
      :selectedRow.sync="selectedActivityLogRow"
      :resetRow="resetRow"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import ActivityLogModal from "@/components/activityLog/Dialogs/ActivityLogModal.vue";
import {
  formatDateDDMMYYYY,
  parseDateYYYYMMDD,
  todayDate,
  oneWeekAgoDate,
  tomorrowDate,
} from "@/utils";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "ActivityLogTable",
  props: [
    "listSkeleton",
    "docId",
    "docType",
    "actDialog",
    "docMode",
    "docItems",
    "docEmailSent",
  ],
  components: {
    ListSkeleton,
    ActivityLogModal,
  },
  mixins: [validationMixin],
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      pagination: {},
      search: this.$route.query.search || "",
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      selectedActivityLogRow: null,
      activityLogDialog: false,
      invoiceStartDateMenu: false,
      invoiceEndDateMenu: false,
      model: {
        invoice_start_date: "",
        invoice_end_date: "",
      },
    };
  },
  async created() {
    const self = this;
    self.getTodayDate();
    self.getOneWeekAgoDate();
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    todayDate: todayDate,
    oneWeekAgoDate: oneWeekAgoDate,
    tomorrowDate: tomorrowDate,
    getTodayDate() {
      this.model.invoice_start_date = this.todayDate();
    },
    getOneWeekAgoDate() {
      this.model.invoice_end_date = this.oneWeekAgoDate();
    },
    async paginate() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.params;
      let filter = null;
      if (this.docMode) {
        filter = {
          page: page,
          limit: itemsPerPage,
          // where: `subject_type|${this.docItems}`,
          // where_or: `subject_type|${this.docType}`,
          // whereOr: `subject_type|${this.docEmailSent}`,
          // where_and: `subject_id|${this.docId}`,
          // whereAnd: `main_doc_id|${this.docId}`,
          // from_created_at: this.model.invoice_end_date,
          // to_created_at: this.tomorrowDate(),
          where_like: this.search
            ? sortBy[0]
              ? sortBy[0] + "|" + this.search
              : sortBy[1]
              ? sortBy[1] + "|" + this.search
              : sortBy[2]
              ? sortBy[2] + "|" + this.search
              : sortBy[3]
              ? sortBy[3] + "|" + this.search
              : sortBy[4]
              ? sortBy[4] + "|" + this.search
              : sortBy[5]
              ? sortBy[5] + "|" + this.search
              : sortBy[6]
              ? sortBy[6] + "|" + this.search
              : "id, log_name, subject_type, description, subject_id, user_id, updated_at,email|" +
                this.search
            : "id,log_name,subject_type,description,subject_id,user_id,updated_at,email|",
          order_by: sortBy[0]
            ? sortBy[0] + "|" + (sortDesc[0] ? "desc" : "asc")
            : "id|desc",
        };
      } else {
        filter = {
          page: page,
          limit: itemsPerPage,
          // where_and: this.docType && this.docId ? `subject_id,subject_type|${this.docId},${this.docType}` : null,
          // from_created_at: this.model.invoice_end_date,
          // to_created_at: this.tomorrowDate(),
          where_like: this.search
            ? sortBy[0]
              ? sortBy[0] + "|" + this.search
              : sortBy[1]
              ? sortBy[1] + "|" + this.search
              : sortBy[2]
              ? sortBy[2] + "|" + this.search
              : sortBy[3]
              ? sortBy[3] + "|" + this.search
              : sortBy[4]
              ? sortBy[4] + "|" + this.search
              : sortBy[5]
              ? sortBy[5] + "|" + this.search
              : sortBy[6]
              ? sortBy[6] + "|" + this.search
              : "id, log_name, subject_type, description, subject_id, user_id, updated_at, email|" +
                this.search
            : "id,log_name,subject_type,description,subject_id,user_id,updated_at,email|",
          order_by: sortBy[0]
            ? sortBy[0] + "|" + (sortDesc[0] ? "desc" : "asc")
            : "id|desc",
        };
      }
      await this.$store.dispatch("activityLog/GetActivityLog", filter);
    },
    buildUrl(val) {
      if (!this.docType) {
        let obj = {};
        if (typeof val === "string" || typeof val === "object") {
          this.page = 1;
          obj.page = 1;
          this.params.page = 1;
        }
        obj.search = this.search;
        obj.page = this.page;
        obj.order_by = this.sortByValue;
        obj.sortDec = this.sortDescValue;
        obj = Object.entries(obj).reduce((acc, [key, val]) => {
          if (!val) return acc;
          else if (key === "page" && val === 1) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router
          .push({
            name: "ActivityLog",
            query: {
              ...obj,
            },
          })
          .catch(() => {});
      }
    },
    resetRow() {
      this.selectedActivityLogRow = {};
    },
    onRowDoubleClick(event, { item }) {
      this.activityLogDialog = true;
      this.selectedActivityLogRow = item;
    },
  },
  async mounted() {
    this.$emit("update:listSkeleton", true);
    let params = null;
    if (this.docMode) {
      params = {
        page: this.page,
        limit: this.limit,
        // where: `subject_type|${this.docItems}`,
        // where_or: `subject_type|${this.docType}`,
        // whereOr: `subject_type|${this.docEmailSent}`,
        // where_and: `subject_id|${this.docId}`,
        // whereAnd: `main_doc_id|${this.docId}`,
        order_by: "id|desc",
      };
    } else {
      params = {
        page: this.page,
        limit: this.limit,
        // where_and: this.docType && this.docId ? `subject_id,subject_type|${this.docId},${this.docType}` : null,
        order_by: "id|desc",
      };
    }
    await this.$store.dispatch("activityLog/GetActivityLog", params);
    await this.$store.dispatch("profile/GetCompanyById");
    this.$emit("update:listSkeleton", false);
  },
  watch: {
    params: {
      handler() {
        this.paginate();
      },
      deep: true,
    },
  },
  computed: {
    formattedFromDate() {
      return this.profileById.date_format
        ? parseDateYYYYMMDD(this.model.invoice_start_date)
        : formatDateDDMMYYYY(this.model.invoice_start_date);
    },
    formattedToDate() {
      return this.profileById.date_format
        ? parseDateYYYYMMDD(this.model.invoice_end_date)
        : formatDateDDMMYYYY(this.model.invoice_end_date);
    },
    params() {
      return {
        ...this.pagination,
        query: this.search,
        from_created_at: this.model.invoice_end_date,
        to_created_at: this.model.invoice_start_date,
      };
    },
    headers() {
      if (this.docType) {
        return [
          {
            text: this.$t("activityLog.id"),
            align: "start",
            sortable: true,
            value: "id",
            width: "70px",
          },
          {
            text: this.$t("activityLog.field.logName"),
            value: "log_name",
            width: "110px",
          },
          {
            text: this.$t("activityLog.field.description"),
            value: "description",
            width: "120px",
          },
          {
            text: this.$t("activityLog.field.subjectType"),
            value: "subject_type",
            width: "120px",
          },
          {
            text: this.$t("activityLog.field.subjectId"),
            value: "subject_id",
            width: "110px",
          },
          {
            text: this.$t("activityLog.activityLogDetailsModal.user"),
            value: "email",
            width: "250px",
            sortable: false,
          },
          {
            text: this.$t("activityLog.field.date"),
            value: "updated_at",
            width: "180px",
          },
        ];
      } else {
        return [
          {
            text: this.$t("activityLog.id"),
            align: "start",
            sortable: true,
            value: "id",
          },
          { text: this.$t("activityLog.field.logName"), value: "log_name" },
          {
            text: this.$t("activityLog.field.description"),
            value: "description",
          },
          {
            text: this.$t("activityLog.field.subjectType"),
            value: "subject_type",
          },
          { text: this.$t("activityLog.field.subjectId"), value: "subject_id" },
          {
            text: this.$t("activityLog.activityLogDetailsModal.user"),
            value: "email",
            width: "240px",
            sortable: false,
          },
          {
            text: this.$t("activityLog.field.date"),
            value: "updated_at",
          },
        ];
      }
    },
    ...mapGetters({
      activityLogList: "activityLog/activityLogList",
      profileById: "profile/profileById",
    }),
  },
  beforeDestroy() {
    this.$store.commit("activityLog/SET_ACTIVITY_LOG", []);
  },
};
</script>
<style scoped>
::v-deep .v-pagination__item {
  word-break: keep-all;
  font-size: 12px;
}
</style>
