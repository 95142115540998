<template>
  <div>
    <template>
      <v-app>
        <div v-for="index in printPage" :key="index" class="html2pdf__page-break">
          <div style="height: 1050px">
          <div
            class="mx-auto"
            style="min-height: 200px"
          >
            <v-card-title class="py-1" v-if="!Object.keys(pdfTemplateValueList).length">
              <v-row dense no-gutters style="width: 100%">
                <v-col cols="12" class="text-center primary--text" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center primary--text" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="font-weight-regular mt-n2">Test Software By BINA</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('invoicePdf.companyAddress') }}</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('invoicePdf.tel') }}: 972-3-7361150 {{ $t('invoicePdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12" class="text-center my-2">
                  <h5 class="text-decoration-underline mt-n2 primary--text">
                    {{ $t('receipt.debitReport') }}
                  </h5>
                </v-col>
              </v-row>
              <v-row dense no-gutters>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.selectedStatus">
                  <span><b>{{$t('receipt.field.status')}}:</b> {{payload.selectedStatus }}</span>
                </v-col>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.customer_id">
                  <span><b>{{$t('receipt.field.customer')}}:</b> {{payload.customer_id}}</span>
                </v-col>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.customer_group_id">
                  <span><b>{{$t('receipt.customerGroup')}}:</b> {{payload.customer_group_id}}</span>
                </v-col>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.employee_id">
                  <span><b>{{$t('receipt.field.agent')}}:</b> {{payload.employee_id}}</span>
                </v-col>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.selectedBank">
                  <span><b>{{$t('receipt.bank')}}:</b> {{payload.selectedBank}}</span>
                </v-col>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.selectedPaymentType">
                  <span><b>{{$t('receipt.paymentType')}}:</b> {{payload.selectedPaymentType}}</span>
                </v-col>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.receipt_date">
                  <span><b>{{$t('receipt.from')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.receipt_date) : parseDateYYYYMMDD(payload.receipt_date)}}</span>
                </v-col>
                <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.receipt_validity">
                  <span><b>{{$t('receipt.to')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.receipt_validity) : parseDateYYYYMMDD(payload.receipt_validity)}}</span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title class="py-1" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
              <v-row dense no-gutters style="width: 100%">
                <v-col cols="12" class="text-center" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="mt-n2">Test Software By BINA</h5>
                  <h5 class="mt-n2">{{ $t('invoicePdf.companyAddress') }}</h5>
                  <h5 class="mt-n2">{{ $t('invoicePdf.tel') }}: 972-3-7361150 {{ $t('invoicePdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12" class="text-center my-2">
                  <h5 class="mt-n2">
                    {{ $t('receipt.debitReport') }}
                  </h5>
                </v-col>
              </v-row>
              <v-row dense no-gutters>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.selectedStatus">
                  <span><b>{{$t('receipt.field.status')}}:</b> {{payload.selectedStatus }}</span>
                </v-col>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.customer_id">
                  <span><b>{{$t('receipt.field.customer')}}:</b> {{payload.customer_id}}</span>
                </v-col>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.customer_group_id">
                  <span><b>{{$t('receipt.customerGroup')}}:</b> {{payload.customer_group_id}}</span>
                </v-col>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.employee_id">
                  <span><b>{{$t('receipt.field.agent')}}:</b> {{payload.employee_id}}</span>
                </v-col>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.selectedBank">
                  <span><b>{{$t('receipt.bank')}}:</b> {{payload.selectedBank}}</span>
                </v-col>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.selectedPaymentType">
                  <span><b>{{$t('receipt.paymentType')}}:</b> {{payload.selectedPaymentType}}</span>
                </v-col>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.receipt_date">
                  <span><b>{{$t('receipt.from')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.receipt_date) : parseDateYYYYMMDD(payload.receipt_date)}}</span>
                </v-col>
                <v-col cols="4" class="text-center my-1 text-start" v-if="payload.receipt_validity">
                  <span><b>{{$t('receipt.to')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.receipt_validity) : parseDateYYYYMMDD(payload.receipt_validity)}}</span>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          <div
            class="mx-auto"
            flat
            ref="itemContent"
          >
            <v-card-title class="py-1">
              <v-row dense>
                <v-col cols="12" class="text-body-2 py-0 text-end">
                  {{ $t('invoicePdf.page') }} {{index}} {{ $t('invoicePdf.of') }} {{printPage}}
                </v-col>
                <v-col cols="12" class="product">
                  <table
                    width="100%"
                    class="my-0 text-body-2"
                    v-if="invoiceItemsObject.length > 0"
                  >
                    <thead>
                      <tr>
                        <th class="text-start ps-1" :style="{width: '40px', wordBreak: 'break-word'}">
                          {{ $t('receipt.field.customerId') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '110px', wordBreak: 'break-word'}">
                          {{ $t('receipt.field.customerName') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '60px', wordBreak: 'break-word'}">
                          {{ $t('receipt.field.afterDiscount') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '120px', wordBreak: 'break-word'}">
                          {{ $t('receipt.field.grandTotal') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '120px', wordBreak: 'break-word'}">
                          {{ $t('receipt.field.total') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '80px', wordBreak: 'break-word'}">
                          {{ $t('receipt.field.country') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="invoiceItemsObject && invoiceItemsObject.length == 0">
                        <td colspan="7" class="text-center">{{$t('common.noDataFound')}}</td>
                      </tr>
                      <template v-else v-for="n in lastIndex[index-1] ? getNumbers(index == 1 ? 1 : lastIndex[index-2] ? lastIndex[index-2] : lastIndex[index-1],lastIndex[index-1] ? lastIndex[index-1] : invoiceItemsObject.length + 1) :  lastIndex[index-2] ? getNumbers(lastIndex[index-2], invoiceItemsObject.length + 1): invoiceItemsObject.length">
                        <tr :key="n" v-if="invoiceItemsObject && invoiceItemsObject.length > 0">
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                            <span>{{ invoiceItemsObject[n-1].customerData.customer_id }}</span>
                          </td>
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                            <span>{{ invoiceItemsObject[n-1].customerData.customer_name }}</span>
                          </td>
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                            <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.after_discount) }}</span>
                          </td>
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                            <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.grand_total) }}</span>
                          </td>
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                            <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.total) }}</span>
                          </td>
                          <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                            <span>{{ invoiceItemsObject[n-1].customerData.country }}</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title v-if="index == printPage" class="py-0">
              <v-row dense no-gutters class="text-end">
                <v-col cols="8">
                </v-col>
                <v-col v-if="accessRight.includes('financial')" cols="4" class="text-body-2">
                  <v-row dense no-gutters>
                    <v-col cols="8" class="font-weight-bold">
                      {{$t('invoicePdf.totalPerReport')}}
                    </v-col>
                    <v-col cols="4" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{ numberWithCommas(model.totalPerReport) }}
                    </v-col>
                  </v-row>
                  <v-row dense no-gutters>
                    <v-col cols="8" class="font-weight-bold">
                      {{$t('invoicePdf.totalPerPaymentType')}}
                    </v-col>
                    <v-col cols="4" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{ numberWithCommas(model.totalPerPaymentType) }}
                    </v-col>
                  </v-row>
                  <v-row v-if="index === printPage" class="text-end">
                    <v-col cols="12" class="text-body-1" v-if="!Object.keys(pdfTemplateValueList).length">
                      {{$t('invoicePdf.withRegards')}}<br>
                      {{model.employee ? model.employee.name : user.name}}<br>
                      <span class="text-body-2">{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                    <v-col cols="12" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
                      {{$t('invoicePdf.withRegards')}}<br>
                      {{model.employee ? model.employee.name : user.name}}<br>
                      <span>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          </div>
          <div class="pb-0 px-4 pt-1" color="white">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-img width="100%" eager :src="bottom" />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-app>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';
export default {
  name: "PrintReceiptReport",
  props:['modelData', 'payload', 'pdfTemplateValueList'],
  data() {
    return{
      company: '',
      printPage: 1,
      rowCount: 0,
      lastIndex: [],
      topLogoUrl: null,
      bottomLogoUrl: null,
      invoiceItemsObject: []
    }
  },
  async mounted() {
    this.company = await this.$store.dispatch('profile/GetCompanyById');
    if(this.company.topLogo) {
      this.getBase64Image(this.company.topLogo.file_path, 'topLogo');
    }
    if(this.company.bottomLogo) {
      this.getBase64Image(this.company.bottomLogo.file_path, 'bottomLogo');
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
  watch: {
    modelData: {
      async handler(){
        this.totalRow;
      },
      deep: true
    }
  },
  computed: {
    model() {
      return this.modelData;
    },
    bottom() {
      return this.bottomLogoUrl;
    },
    top() {
      return this.topLogoUrl;
    },
    ...mapGetters({
      locale: 'locale',
      accessRight: 'accessRight',
      profileById: 'profile/profileById',
      user: 'user',
    }),
    totalRow() {
      let rowCount = 0;
      /* eslint-disable */
      this.printPage = 1;
      let beforeRowCount = rowCount;
      if(this.model.data) {
        /* eslint-disable */
        this.invoiceItemsObject = [];
        this.lastIndex = [];
        this.model.data.forEach(mainItem => {
          this.invoiceItemsObject.push({customerData: mainItem});
        })
        /* eslint-enable */
        this.invoiceItemsObject.forEach((element,index) => {
            let allItemCustomerNameCount = 0;
            if(index == this.invoiceItemsObject.length - 1) {
              rowCount = Number(rowCount) + 5;
            }
            if (element.customerData) {
              let itemCustomerNameCount = 0;
              if(element.customerData.customer_name) {
                itemCustomerNameCount = element.customerData.customer_name.match(/.{1,20}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(element.customerData.customer_name.match(/.{1,20}/g).length)) : Number(itemCustomerNameCount) + 1;
                allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
                rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
              } else {
                rowCount = Number(rowCount) + 1;
              }
            } else {
              let itemCustomerNameCount = 0;
              if(element.customer_name) {
                itemCustomerNameCount = element.customer_name.match(/.{1,19}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(element.customer_name.match(/.{1,19}/g).length)) : Number(itemCustomerNameCount) + 1;
                allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
              }
              rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
            }
            let tempCount = 0;
            if(tempCount < rowCount && rowCount >= 20) {
              tempCount = rowCount;
              this.lastIndex.push(this.printPage == 1 ? index+1 : index+1);
              this.printPage += 1;
              rowCount = Number(allItemCustomerNameCount) + beforeRowCount;
            }
          });
      }
      return rowCount;
    }
  },
  methods: {
    formatDateDDMMYYYY,
    parseDateYYYYMMDD,
    getBase64Image(imgUrl, type) {
      let img = new Image();
      let self = this;
      // onload fires when the image is fully loadded, and has width and height
      img.onload = function(){
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png")
        type === 'bottomLogo' ? self.bottomLogoUrl = dataURL : self.topLogoUrl = dataURL; // the base64 string
      };
      // set attributes and src 
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = imgUrl + '?' + new Date().toLocaleString('sv-SE');
    },
    getNumbers:function(start,stop){
      return new Array(stop-start).fill(start).map((n,i)=>n+i);
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : 0;
    },
  }
}
</script>
<style scoped>
.product table {
  border-collapse: collapse;
}
.product table, .product table td {
  border: 0px solid black;
}
.product table {
  border-width: 1px 1px 1px 1px;
}
.product table td {
  border-width: 1px 0 0 1px;
  border-left: none;
  border-right: none;
}
.product th {
  border: 0px solid black;
  border-width: 0 0 0 1px;
  border-left: none;
  border-right: none;
}
#footer .v-image {
  width: 110px;
  right: 0;
  position: absolute;
  left: 0;
}
</style>
