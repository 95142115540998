<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="60%"
    >
      <EmployeeListTable :listSkeleton.sync="listSkeleton" @selectCustomerEmployee="selectCustomerEmployee" :type.sync="type">
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false);">mdi-close</v-icon>
          </v-card-text>
        </template>
      </EmployeeListTable>
    </v-dialog>
  </div>
</template>
<script>
import EmployeeListTable from '@/views/companyApp/employee/EmployeeListTable';
export default {
  name: "EmployeeDialog",
  props: ["dialog"],
  components: {
    EmployeeListTable
  },
  data() {
    return {
      listSkeleton: false,
      type: 'CustomerEmployee',
    }
  },
  watch: {
    dialog(val) {
      if(!val) {
        this.type = null;
      } else {
        this.type = 'CustomerEmployee';
        this.$store.dispatch('employee/GetEmployee', {page: 1, limit: 10, order_by: 'name|asc'});
      }
    }
  },
  methods: {
    selectCustomerEmployee(item) {
      this.$emit('onEmployeeChange', item);
      this.$emit('update:dialog', false);
    }
  },
}
</script>