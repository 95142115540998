<template>
  <div>
    <v-dialog
      class="pa-0"
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="50%"
    >
      <v-card>
        <v-card-title class="pa-2">
          <h5 class="primary--text font-weight-bold">{{$t('common.sendByMail')}}</h5>
          <v-spacer />
          <v-btn
            icon
            @click="$emit('update:dialog', false);"
          >
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="text-h5 px-4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <Alert :alert.sync="alert" :message="message" />
            <v-row>
              <v-col cols="12" v-if="type !== 'DebitReportPDF'">
                <v-autocomplete
                  hide-details="auto"
                  class="me-2 multi-select-field"
                  v-model="selectedContact"
                  :items="contactList"
                  item-text="name"
                  item-value="id"
                  clearable
                  return-object
                  multiple
                  allow-overflow
                  small-chips
                  deletable-chips
                  chips
                  :label="$t('common.selectContacts')"
                  @input="changeSelectedContact"
                />
              </v-col>
              <v-col cols="12" v-if="type !== 'DebitReportPDF'">
                <v-text-field
                  hide-details="auto"
                  autocomplete="new-password"
                  v-model.trim="model.contactemails"
                  :label="$t('common.contactEmails')"
                  required
                  readonly
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details="auto"
                  autocomplete="new-password"
                  v-model.trim="model.email"
                  :label="$t('common.otherEmails')"
                  :rules="emailValid"
                  required
                />
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  small
                  :loading="loading"
                  :disabled="!valid"
                  @click="sendEmail"
                >
                  {{$t('common.sendEmail')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Alert from "@/components/Alert"
export default {
  name:"EmailDialog",
  props: ['dialog', 'emailArray', 'customerId', 'otherEmail', 'type'],
  components: {
    Alert
  },
  data() {
    return {
      model: {},
      loading: false,
      valid: false,
      selectedContact: [],
      contactList: [],
      message: '',
      alert: false
    }
  },
  computed: {
    emailValid() {
      return [
        // v => !!v || this.$t('formRules.emailRequired'),
        v => !v || /.+@.+\..+/.test(v) || this.$t('formRules.emailValidMessage'),
        v => !v || (v && v.length <= 64) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('contact.field.email'), type: this.$t('common.less'), size: 64 }),
      ];
    },
    ...mapGetters({
      // contactList: 'contact/contactList',
    })
  },
  watch: {
    async dialog() {
      if(!this.dialog) {
        this.$refs.form.reset();
        this.model = {};
        this.loading = false;
        this.alert = false;
        this.message = '';
        this.contactList = [];
        this.selectedContact = [];
      } else {
        if (this.type === 'TrialBalanceReportPDF' || this.type === 'DebitReportPDF' || this.type === 'SalesReportPDF' || this.type === 'AccountingCardReportPDF' || this.type === 'DepositPDF') {
          this.contactList = await this.$store.dispatch('contact/GetContact', {where_and: 'record_type|customers', order_by: 'name|asc'})
        } else if (this.type === 'SupplierDeliveryNotePDF' || this.type === 'PurchaseOrderPDF' || this.type === 'SupplierCreditInvoicePDF' || this.type === 'SupplierInvoicePDF' || this.type === 'PurchaseReportPDF' || this.type === 'SupplierPaymentPdf' || this.type === 'SupplierDebtsReport' || this.type === 'CashFlowReportPDF') {
          this.contactList = await this.$store.dispatch('contact/GetContact', {where_and: 'record_type|suppliers', order_by: 'name|asc'})
        } else {
          this.contactList = await this.$store.dispatch('contact/GetContact', {where_and: 'record_type_number,record_type|' + this.customerId+',customers', order_by: 'name|asc'})
        }
        this.model.email = this.otherEmail;
      }
    }
  },
  methods: {
    changeSelectedContact() {
      if (this.selectedContact.length > 0) {
        this.model.contactemails = '';
        this.selectedContact.forEach(item => {
          this.model.contactemails = item?.email && this.model.contactemails ? this.model.contactemails + ', ' + item?.email : item?.email && !this.model.contactemails ? item?.email : !item?.email && this.model.contactemails ? this.model.contactemails : this.model.contactemails;
        })
      } else {
        this.model.contactemails = ''
      }
    },
    async sendEmail() {
      if(this.$refs.form.validate()){
        this.loading = true;
        let concatEmail = []
        if (this.selectedContact.length > 0) {
          this.selectedContact.forEach(item => {
            if(item.email) {
              concatEmail.push(item.email)
            }
          });
        }
        if (this.model.email) {
          concatEmail.push(this.model.email)
        }
        if (concatEmail.length > 0) {
          await this.$emit('update:emailArray', concatEmail);
          await this.$emit('sendEmailPdf');
        } else {
          this.message = "formRules.formAlert";
          this.alert = true;
          this.loading = false;
        }
      }
    }
  },
}
</script>