<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-dialog
      v-model="dialog"
      width="500"
      @click:outside="$emit('update:dialog', false)"
    >
      <v-card>
        <v-card-title class="primary--text px-4 py-1 text-body-1">
          {{ $t("auxiliaryTable.confirm") }}
          <v-spacer />
          <v-btn icon @click="$emit('update:dialog', false)">
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="text-body-1 mt-4">
          {{ $t("common.copyAndCreateNew") }}
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                hide-details="auto"
                height="30%"
                dense
                v-model="selectedGroup"
                :items="groupValue"
                item-text="name"
                item-value="id"
                clearable
                flat
                return-object
                single-line
                :rules="documentTypeValid"
                :label="$t('customer.documentType')"
              />
            </v-col>
            <v-col cols="4"></v-col>
            <v-col cols="4" class="d-flex justify-center">
              <v-btn
                :disabled="!valid"
                color="primary"
                class="text-center text-capitalize my-3"
                small
                @click="confirm"
              >
                {{ $t("route.createNewDocument") }}
              </v-btn>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  name: "CommonCopyAndCreateDialog",
  props: ["dialog", "modelData", "type", "originModel"],
  data() {
    return {
      selectedGroup: null,
      valid: false,
    };
  },
  computed: {
    documentTypeValid() {
      return [(v) => !!v || this.$t("formRules.documentTypeRequired")];
    },
    groupValue() {
      let groups = [
        { id: 1, name: this.$t("route.quotation") },
        { id: 2, name: this.$t("route.order") },
        { id: 3, name: this.$t("route.proforma") },
        { id: 4, name: this.$t("route.delivery") },
        { id: 5, name: this.$t("route.invoice") },
        { id: 6, name: this.$t("route.receiptTaxInvoice") },
        { id: 7, name: this.$t("route.creditInvoice") },
        { id: 8, name: this.$t("route.returnCertificate") },
      ];
      // if type === order then order display as a first index
      const typeIndex = groups.findIndex(group => group.name.toLowerCase() === this.type.toLowerCase());
      if (typeIndex !== -1) {
        const typeGroup = groups.splice(typeIndex, 1)[0];
        groups.unshift(typeGroup);
      }
      return groups;
    }
  },
  methods: {
    confirm() {
      if (this.$refs.form.validate()) {
        if (this.type === 'Quotation') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ name: 'addQuotation', params: {copiedModel: this.modelData, type: 'Quotation'} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ path: "/addOrder", query: {quotationId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create'} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ path: "/addProforma", query: {quotationId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ path: "/addDelivery", query: {quotationId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ path: "/addInvoice", query: {quotationId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ path: "/addReceiptTaxInvoice", query: {quotationId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ path: "/addCreditInvoice", query: {quotationId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else {
            this.$router.push({ path: "/addReturnCertificate", query: {quotationId: this.modelData.id, customerId: this.modelData.customer_id} });
          }
        } else if (this.type === 'Order') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ path: '/addQuotation', query: {orderId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ name: 'addOrder', params: {copiedModel: this.modelData, type: 'Order'} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ path: '/addProforma', query: { orderId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create' }});
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ path: '/addDelivery', query: { orderId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create' }})
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ path: '/addInvoice', query: { orderId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create' }});
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ path: "/addReceiptTaxInvoice", query: {orderId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create'} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ path: '/addCreditInvoice', query: { orderId: this.modelData.id, customerId: this.modelData.customer_id }});
          } else {
            this.$router.push({ path: "/addReturnCertificate", query: {orderId: this.modelData.id, customerId: this.modelData.customer_id} });
          }
        } else if (this.type === 'Proforma Invoice') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ path: '/addQuotation', query: {proformaId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ path: '/addOrder', query: {proformaId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ name: 'addProforma', params: {copiedModel: this.modelData.id, type: 'Proforma'} });
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ path: '/addDelivery', query: {proformaId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create'} })
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ path: '/addInvoice', query: {proformaId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create'} });
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ path: "/addReceiptTaxInvoice", query: {proformaId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ path: '/addCreditInvoice', query: {proformaId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else {
            this.$router.push({ path: "/addReturnCertificate", query: {proformaId: this.modelData.id, customerId: this.modelData.customer_id} });
          }
        } else if (this.type === 'Delivery') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ path: '/addQuotation', query: {deliveryId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ path: '/addOrder', query: {deliveryId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ path: '/addProforma', query: {deliveryId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ name: 'addDelivery', params: {copiedModel: this.modelData, type: 'Delivery'} })
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ path: '/addInvoice', query: {deliveryId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create'} });
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ path: "/addReceiptTaxInvoice", query: {deliveryId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ path: '/addCreditInvoice', query: {deliveryId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else {
            this.$router.push({ path: "/addReturnCertificate", query: {deliveryId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create'} });
          }
        } else if (this.type === 'Invoice') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ path: '/addQuotation', query: {invoiceId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ path: '/addOrder', query: {invoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ path: '/addProforma', query: {invoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ path: '/addDelivery', query: {invoiceId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ name: 'addInvoice', params: {copiedModel: this.modelData.id, type: 'Invoice'} });
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ path: "/addReceiptTaxInvoice", query: {invoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ name: 'addCreditInvoice', params: {invoiceId: this.modelData.id, creditInvoiceType: 1, type: 'copy-create'} })
          } else {
            this.$router.push({ path: "/addReturnCertificate", query: {invoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          }
        } else if (this.type === 'Receipt Tax Invoice') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ path: '/addQuotation', query: {receiptTaxInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ path: '/addOrder', query: {receiptTaxInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ path: '/addProforma', query: {receiptTaxInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ path: '/addDelivery', query: {receiptTaxInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ path: '/addInvoice', query: {receiptTaxInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ name: 'addReceiptTaxInvoice', params: {copiedModel: this.modelData.id, type: 'Receipt Tax Invoice'} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ path: '/addCreditInvoice', query: {receiptTaxInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else {
            this.$router.push({ path: "/addReturnCertificate", query: {receiptTaxInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          }
        } else if (this.type === 'Credit Invoice') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ path: '/addQuotation', query: {creditInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ path: '/addOrder', query: {creditInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ path: '/addProforma', query: {creditInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ path: '/addDelivery', query: {creditInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ path: '/addInvoice', query: {creditInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ path: '/addReceiptTaxInvoice', query: {creditInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ name: 'addCreditInvoice', params: {copiedModel: this.modelData.id, type: 'Credit Invoice'} });
          } else {
            this.$router.push({ path: "/addReturnCertificate", query: {creditInvoiceId: this.modelData.id, customerId: this.modelData.customer_id} });
          }
        } else if (this.type === 'Return Certificate') {
          if (this.selectedGroup.id == 1) {
            this.$router.push({ path: '/addQuotation', query: {returnCertificateId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 2) {
            this.$router.push({ path: '/addOrder', query: {returnCertificateId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 3) {
            this.$router.push({ path: '/addProforma', query: {returnCertificateId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 4) {
            this.$router.push({ path: '/addDelivery', query: {returnCertificateId: this.modelData.id, customerId: this.modelData.customer_id} })
          } else if (this.selectedGroup.id == 5) {
            this.$router.push({ path: '/addInvoice', query: {returnCertificateId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create'} });
          } else if (this.selectedGroup.id == 6) {
            this.$router.push({ path: '/addReceiptTaxInvoice', query: {returnCertificateId: this.modelData.id, customerId: this.modelData.customer_id} });
          } else if (this.selectedGroup.id == 7) {
            this.$router.push({ path: "/addCreditInvoice", query: {returnCertificateId: this.modelData.id, customerId: this.modelData.customer_id, type: 'copy-create', invoiceData: this.originModel} });
          } else {
            this.$router.push({ name: 'addReturnCertificate', params: {copiedModel: this.originModel} })
          }
        }
      }
    },
  },
};
</script>