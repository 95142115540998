import { render, staticRenderFns } from "./PrintCustomerReport.vue?vue&type=template&id=1864e04e&scoped=true&"
import script from "./PrintCustomerReport.vue?vue&type=script&lang=js&"
export * from "./PrintCustomerReport.vue?vue&type=script&lang=js&"
import style0 from "./PrintCustomerReport.vue?vue&type=style&index=0&id=1864e04e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1864e04e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VCardTitle,VCol,VImg,VRow})
