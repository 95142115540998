<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="60%"
    >
      <CustomerListTable :listSkeleton.sync="listSkeleton" @selectQuotationCustomer="selectQuotationCustomer" :type.sync="type" :sortByName="true">
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false);">mdi-close</v-icon>
          </v-card-text>
        </template>
      </CustomerListTable>
    </v-dialog>
  </div>
</template>
<script>
import CustomerListTable from '@/views/companyApp/customer/CustomerListTable';
export default {
  name: "CustomerDialog",
  props: ["dialog"],
  components: {
    CustomerListTable
  },
  data() {
    return {
      listSkeleton: false,
      type: 'QuotationCustomer',
    }
  },
  watch: {
    dialog(val) {
      if(!val) {
        this.type = null;
      } else {
        this.type = 'QuotationCustomer';
        this.$store.dispatch('customer/GetCustomer', {page: 1, limit: 10, order_by: 'name_1|asc'});
      }
    }
  },
  methods: {
    selectQuotationCustomer(item) {
      this.$emit('onCustomerChange', item);
      this.$emit('update:dialog', false);
    }
  },
}
</script>