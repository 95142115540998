<template>
  <div>
    <v-row :dense="true">
      <v-col cols="4">
        <v-skeleton-loader
          class="pt-4"
          type="list-item-avatar"
        />
      </v-col>
    </v-row>
    <v-row class="pl-10" :dense="true">
      <v-col cols="6" :style="{borderLeft: '1px solid var(--v-secondary-lighten5) !important'}">
        <v-skeleton-loader
          class="my-1"
          type="card-heading"
        />
        <v-skeleton-loader
          class="my-1"
          type="card-heading"
        />
        <v-skeleton-loader
          class="my-1"
          type="card-heading"
        />
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader
          class="my-1"
          type="card-heading"
        />
        <v-skeleton-loader
          class="my-1"
          type="card-heading"
        />
        <v-skeleton-loader
          class="my-1"
          type="card-heading"
        />
      </v-col>
    </v-row>
    <v-row :dense="true">
      <v-col cols="4">
        <v-skeleton-loader
          class="pt-2"
          type="list-item-avatar"
        />
      </v-col>
    </v-row>
    <v-row :dense="true">
      <v-col cols="4">
        <v-skeleton-loader
          class="pt-2"
          type="list-item-avatar"
        />
      </v-col>
    </v-row>
    <v-row :dense="true">
      <v-col cols="4">
        <v-skeleton-loader
          class="pt-2"
          type="list-item-avatar"
        />
      </v-col>
    </v-row>
    <v-row :dense="true">
      <v-col cols="5">
      </v-col>
      <v-col cols="2">
        <v-skeleton-loader
          class="my-1"
          type="button"
        />
      </v-col>
      <v-col cols="5">
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "FormSkeleton",
}
</script>