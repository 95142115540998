<template>
  <span>
    <v-list-item dense class="primary--text" @click="calendarForm">
      <v-list-item-content>
        <v-list-item-title>
          <v-icon small class="primary--text"> mdi-calendar </v-icon>
          {{ $t("calendar.calendar") }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <CalendarForm
      v-if="calendarDialog"
      :dialog.sync="calendarDialog"
      :docId="docId"
      :docType="docType"
      :modelData="model"
      :getByRes="getByRes"
    />
  </span>
</template>

<script>
import CalendarForm from "@/components/CalendarForm";
export default {
  name: "CommonCalendar",
  props: ["docId", "docType", "model"],
  components: { CalendarForm },
  data() {
    return {
      calendarDialog: false,
      getByRes: null,
    };
  },
  methods: {
    async calendarForm() {
      await this.$store
        .dispatch(
          "calendar/GetCalendarById",
          `?where=document_id|${this.docId}&where_and=document_type|${this.docType}`
        )
        .then(async (res) => {
          if (res) {
            this.getByRes = await Object.assign({
              ...res[0],
              start_time: res[0]?.start_time.split("T")[1].substring(0, 5),
              end_time: res[0]?.end_time.split("T")[1].substring(0, 5),
              start_date: res[0]?.start_date.split("T")[0],
              end_date: res[0]?.end_date.split("T")[0],
              employee_id: res[0]?.CalanderEventDetails.map((item) => ({
                id: item.employee_id,
                name: item.employee_name,
              })),
            });
          }
        });
      this.calendarDialog = true;
    },
  },
};
</script>
