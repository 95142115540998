<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-dialog
      v-model="dialog"
      width="500"
      @click:outside="$emit('update:dialog', false)"
    >
      <v-card>
        <v-card-title class="primary--text px-4 py-1 text-body-1">
          {{ $t("auxiliaryTable.confirm") }}
          <v-spacer />
          <v-btn icon @click="$emit('update:dialog', false)">
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="text-body-1 mt-4">
          {{ $t("common.chooseDyamicTemplate") }}
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                hide-details="auto"
                height="30%"
                dense
                v-model="selectedTemplate"
                :items="templateValue"
                item-text="name"
                item-value="id"
                clearable
                flat
                return-object
                single-line
                :rules="templateSelectionValid"
                :label="$t('common.selectTemplate')"
              />
              <!-- @input="onTemplateSelect" -->
            </v-col>
            <v-col cols="4"></v-col>
            <v-col cols="4" class="d-flex justify-center">
              <v-btn
                :disabled="!valid"
                color="primary"
                class="text-center text-capitalize my-3"
                small
                @click="confirm"
              >
                {{ $t("deposit.confirm") }}
              </v-btn>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "DynamicTemplateDialog",
  props: ["dialog"],
  data() {
    return {
      selectedTemplate: null,
      valid: false,
    };
  },
  async mounted() {
    await this.$store.dispatch('pdfTemplate/GetPdfTemplate');
  },
  computed: {
    ...mapGetters({
      pdfTemplateList: "pdfTemplate/pdfTemplateList",
    }),
    templateValue() {
      return Array.from(this.pdfTemplateList, (arr, index) => ({
        id: index + 1,
        name: arr.template_name,
        ...arr
      }));
    },
    templateSelectionValid() {
      return [(v) => !!v || this.$t("formRules.templateSelectionValid")];
    },
  },
  methods: {
    // onTemplateSelect() {
    //   this.$emit('selectedTemplateForPdf', this.selectedTemplate);
    //   this.$emit('update:dialog', false);
    // },
    confirm() {
      this.$emit('selectedTemplateForPdf', this.selectedTemplate);
      this.$emit('update:dialog', false);
    }
  },
};
</script>