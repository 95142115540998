var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"content-class":"custom-dialog-content","max-width":"60%"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0 text-end"},[_c('v-icon',{staticClass:"mt-2 mx-2 primary--text",on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"text-h5 px-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('Alert',{attrs:{"alert":_vm.alert,"message":_vm.message},on:{"update:alert":function($event){_vm.alert=$event}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"pb-0",attrs:{"vertical":"","non-linear":"","flat":""},model:{value:(_vm.e6),callback:function ($$v) {_vm.e6=$$v},expression:"e6"}},[_c('v-stepper-step',{staticClass:"py-3 px-2 text-h6",attrs:{"editable":"","step":"1"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","dense":""}},[_c('h6',{staticClass:"primary--text mt-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t("calendar.calendarEvents"))+" ")])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"6","dense":""}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","disabled":!_vm.deleteDisable,"small":""},on:{"click":_vm.deleteEvent}},[_vm._v(" "+_vm._s(_vm.$t("calendar.delete"))+" ")]),_c('v-btn',{attrs:{"color":"primary","small":"","disabled":!_vm.valid ||
                          _vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show')),"loading":_vm.loading},on:{"click":_vm.saveCalendarEvent}},[_vm._v(" "+_vm._s(_vm.$t("contact.save"))+" ")])],1)],1)],1),_c('v-stepper-content',{staticClass:"ml-5 mb-auto pb-0 mr-3 py-2 px-2",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"asterisk",attrs:{"hide-details":"auto","label":_vm.$t('calendar.title'),"rules":_vm.titleValid,"readonly":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show')),"required":""},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"asterisk",attrs:{"hide-details":"auto","label":_vm.$t('calendar.details'),"rules":_vm.detialsRequired,"readonly":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show')),"required":""},model:{value:(_vm.model.details),callback:function ($$v) {_vm.$set(_vm.model, "details", $$v)},expression:"model.details"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"caption",staticStyle:{"font-size":"60%"},domProps:{"textContent":_vm._s(_vm.$t('calendar.startTime'))}}),_c('vue-timepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"asterisk",attrs:{"disabled":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show')),"placeholder":_vm.$t('delivery.field.hour'),"enableRtl":"false"},scopedSlots:_vm._u([{key:"clearButton",fn:function(){return [_c('v-icon',{class:_vm.locale === 'he' ? 'hebrewposition' : ''},[_vm._v("mdi-close")])]},proxy:true}]),model:{value:(_vm.model.start_time),callback:function ($$v) {_vm.$set(_vm.model, "start_time", $$v)},expression:"model.start_time"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"caption",staticStyle:{"font-size":"60%"},domProps:{"textContent":_vm._s(_vm.$t('calendar.endTime'))}}),_c('vue-timepicker',{staticClass:"asterisk",attrs:{"disabled":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show')),"placeholder":_vm.$t('delivery.field.hour'),"enableRtl":"false"},scopedSlots:_vm._u([{key:"clearButton",fn:function(){return [_c('v-icon',{class:_vm.locale === 'he' ? 'hebrewposition' : ''},[_vm._v("mdi-close")])]},proxy:true}]),model:{value:(_vm.model.end_time),callback:function ($$v) {_vm.$set(_vm.model, "end_time", $$v)},expression:"model.end_time"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.model.start_date,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show'))},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, "start_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.model, "start_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"asterisk",attrs:{"hide-details":"auto","label":_vm.$t('calendar.startDate'),"rules":_vm.profileById.date_format ? _vm.dateValid2 : _vm.dateValid,"readonly":_vm.deleteDisable ||
                              (_vm.accessRight.length < 2 &&
                                _vm.accessRight.includes('show'))},model:{value:(_vm.formattedStartDate),callback:function ($$v) {_vm.formattedStartDate=$$v},expression:"formattedStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{on:{"change":function($event){return _vm.$refs.startDateMenu.save(_vm.model.start_date)}},model:{value:(_vm.model.start_date),callback:function ($$v) {_vm.$set(_vm.model, "start_date", $$v)},expression:"model.start_date"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){_vm.$refs.startDateMenu.save(_vm.todayDate())}}},[_vm._v(_vm._s(_vm.$t("route.today")))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.model.end_date,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show'))},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, "end_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.model, "end_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"asterisk",attrs:{"hide-details":"auto","label":_vm.$t('calendar.endDate'),"rules":_vm.profileById.date_format ? _vm.dateValid2 : _vm.dateValid,"readonly":_vm.deleteDisable ||
                              (_vm.accessRight.length < 2 &&
                                _vm.accessRight.includes('show'))},model:{value:(_vm.formattedEndDate),callback:function ($$v) {_vm.formattedEndDate=$$v},expression:"formattedEndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.model.start_date ? _vm.model.start_date : ''},on:{"change":function($event){return _vm.$refs.endDateMenu.save(_vm.model.end_date)}},model:{value:(_vm.model.end_date),callback:function ($$v) {_vm.$set(_vm.model, "end_date", $$v)},expression:"model.end_date"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){_vm.$refs.endDateMenu.save(_vm.todayDate())}}},[_vm._v(_vm._s(_vm.$t("route.today")))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{staticClass:"asterisk",attrs:{"autocomplete":"new-password","items":_vm.employeeList.data,"item-text":"name","item-value":"id","flat":"","return-object":"","hide-no-data":"","hide-details":"auto","required":"","rules":_vm.employeeNameRequired,"search-input":_vm.employeeSearch,"label":_vm.$t('calendar.employee'),"multiple":"","readonly":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show'))},on:{"change":_vm.onEmployeeChange,"update:searchInput":function($event){_vm.employeeSearch=$event},"update:search-input":function($event){_vm.employeeSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium d-flex align-center",staticStyle:{"font-size":"0.8125rem"}},[_c('v-checkbox',{staticClass:"mb-n1",attrs:{"dense":""},model:{value:(_vm.selectedEmployeesMap[item.id]),callback:function ($$v) {_vm.$set(_vm.selectedEmployeesMap, item.id, $$v)},expression:"selectedEmployeesMap[item.id]"}}),_vm._v(" "+_vm._s(((item.id) + ". " + (item.name)))+" ")],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasEmployeeNextPage),expression:"hasEmployeeNextPage"},{name:"intersect",rawName:"v-intersect",value:(_vm.infiniteScrollOnEmployee),expression:"infiniteScrollOnEmployee"}],ref:"load",staticClass:"loader text-center"},[(
                                _vm.employeeList.data.length <
                                _vm.employeeList.meta.total
                              )?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.selectedEmployee),callback:function ($$v) {_vm.selectedEmployee=$$v},expression:"selectedEmployee"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{staticClass:"my-2 asterisk",attrs:{"hide-details":"auto","items":_vm.priorityList,"rules":_vm.priorityRequired,"item-text":"name","item-value":"slug","flat":"","return-object":"","single-line":"","label":_vm.$t('calendar.priority'),"readonly":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show'))},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"mx-3",attrs:{"color":item.color}},[_vm._v("mdi-circle")])]}},{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"mx-3",attrs:{"color":item.color}},[_vm._v("mdi-circle")])]}}]),model:{value:(_vm.model.priority),callback:function ($$v) {_vm.$set(_vm.model, "priority", $$v)},expression:"model.priority"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{staticClass:"my-2 asterisk",attrs:{"hide-details":"auto","dense":"","items":_vm.typeValue,"rules":_vm.typeValid,"item-text":"description","item-value":"id","flat":"","return-object":"","single-line":"","label":_vm.$t('calendar.eventType'),"readonly":_vm.deleteDisable ||
                          (_vm.accessRight.length < 2 &&
                            _vm.accessRight.includes('show'))},model:{value:(_vm.model.event_type),callback:function ($$v) {_vm.$set(_vm.model, "event_type", $$v)},expression:"model.event_type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"hide-details":"auto","dense":"","items":_vm.statusList,"item-text":"name","item-value":"id","flat":"","return-object":"","single-line":"","label":_vm.$t('quotation.field.status')},on:{"change":_vm.changeCalendarStatus},model:{value:(_vm.selectedStatusOfCalendar),callback:function ($$v) {_vm.selectedStatusOfCalendar=$$v},expression:"selectedStatusOfCalendar"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('RefreshToken',{attrs:{"dialog":_vm.refreshTokenDialog},on:{"update:dialog":function($event){_vm.refreshTokenDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }