<template>
  <div>
    <template>
      <v-app>
        <div v-for="index in printPage" :key="index" class="html2pdf__page-break">
          <div style="height: 1050px">
          <div
            class="mx-auto"
            style="min-height: 200px"
          >
            <v-card-title class="py-1" v-if="!Object.keys(pdfTemplateValueList).length">
              <v-row dense no-gutters>
                <v-col cols="12" class="text-center primary--text" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center primary--text" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="font-weight-regular mt-n2">Test Software By BINA</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('depositPdf.companyAddress') }}</h5>
                  <h5 class="font-weight-regular mt-n2">{{ $t('depositPdf.tel') }}: 972-3-7361150 {{ $t('depositPdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12">
                  <h5 class="font-weight-regular mt-n2">{{ $t('depositPdf.vatRegNo') }} {{company.vat_reg_no}} </h5>
                </v-col>
                <v-col cols="12" class="text-center">
                  <h5 class="text-decoration-underline mt-n2 primary--text">
                    {{ $t('depositPdf.depositNo') }} {{model.id}}
                  </h5>
                </v-col>
                <v-col cols="12">
                  <table
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td style="border: 0.1mm solid #eee;">
                          <v-row dense>
                            <v-col cols="6">
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('depositPdf.bank') }}:</span> <span class="primary--text">{{model.bank ? model.bank.name : ''}}</span></p>
                              <p class="text-body-2 mb-0" v-if="model.bank && model.bank.account_no"><span class="font-weight-bold">{{ $t('bank.field.accountNo') }}:</span> <span class="primary--text">{{model.bank ? model.bank.account_no : ''}}</span></p>
                              <p class="text-body-2 mb-0" v-if="model.bank && model.bank.branch_no"><span class="font-weight-bold">{{ $t('bank.field.branchNo') }}:</span> <span class="primary--text">{{model.bank ? model.bank.branch_no : ''}}</span></p>
                              <p class="text-body-2 mb-0" v-if="model.bank && model.bank.branch_name"><span class="font-weight-bold">{{ $t('bank.field.branchName') }}:</span> <span class="primary--text">{{model.bank ? model.bank.branch_name : ''}}</span></p>
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('depositPdf.type') }}:</span> <span class="primary--text">{{model.paymentTypes ? model.paymentTypes.description : ''}}</span></p>
                            </v-col>
                            <v-col cols="6" class="text-end">
                              <p class="text-body-2 mb-0"><span class="font-weight-bold">{{ $t('depositPdf.depositDate') }}:</span> <span class="primary--text">{{company.date_format? formatDateDDMMYYYY(model.deposit_date): model.deposit_date}}</span></p>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title class="py-1" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
              <v-row dense no-gutters>
                <v-col cols="12" class="text-center" v-if="top">
                  <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                  <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                </v-col>
                <v-col cols="12" class="text-center" v-else>
                  <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                  <h5 class="mt-n2">Test Software By BINA</h5>
                  <h5 class="mt-n2">{{ $t('depositPdf.companyAddress') }}</h5>
                  <h5 class="mt-n2">{{ $t('depositPdf.tel') }}: 972-3-7361150 {{ $t('depositPdf.fax') }}: 972-3-7361150</h5>
                  <h6 class="mt-n2">bina@binaw.com</h6>
                </v-col>
                <v-col cols="12">
                  <h5 class="mt-n2">{{ $t('depositPdf.vatRegNo') }} {{company.vat_reg_no}} </h5>
                </v-col>
                <v-col cols="12" class="text-center">
                  <h5 class="mt-n2">
                    {{ $t('depositPdf.depositNo') }} {{model.id}}
                  </h5>
                </v-col>
                <v-col cols="12">
                  <table
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td style="border: 0.1mm solid #eee;">
                          <v-row dense>
                            <v-col cols="6">
                              <p class="mb-0"><span>{{ $t('depositPdf.bank') }}:</span> <span>{{model.bank ? model.bank.name : ''}}</span></p>
                              <p class="mb-0" v-if="model.bank && model.bank.account_no"><span>{{ $t('bank.field.accountNo') }}:</span> <span>{{model.bank ? model.bank.account_no : ''}}</span></p>
                              <p class="mb-0" v-if="model.bank && model.bank.branch_no"><span>{{ $t('bank.field.branchNo') }}:</span> <span>{{model.bank ? model.bank.branch_no : ''}}</span></p>
                              <p class="mb-0" v-if="model.bank && model.bank.branch_name"><span>{{ $t('bank.field.branchName') }}:</span> <span>{{model.bank ? model.bank.branch_name : ''}}</span></p>
                              <p class="mb-0"><span>{{ $t('depositPdf.type') }}:</span> <span>{{model.paymentTypes ? model.paymentTypes.description : ''}}</span></p>
                            </v-col>
                            <v-col cols="6" class="text-end">
                              <p class="mb-0"><span>{{ $t('depositPdf.depositDate') }}:</span> <span>{{company.date_format? formatDateDDMMYYYY(model.deposit_date): model.deposit_date}}</span></p>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          <div
            class="mx-auto"
            flat
            ref="itemContent"
          >
            <v-card-title class="py-1">
              <v-row dense>
                <v-col cols="12" class="text-body-2 py-0 text-end">
                  {{ $t('depositPdf.page') }} {{index}} {{ $t('depositPdf.of') }} {{printPage}}
                </v-col>
                <v-col cols="12" class="product">
                  <table
                    width="100%"
                    class="my-0 text-body-2"
                    v-if="depositPaymentObject"
                  >
                    <thead>
                      <tr>
                        <th class="text-start ps-1" :style="{width: '40px'}">
                          {{ $t('depositPdf.no') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '160px'}">
                          {{ $t('depositPdf.customerName') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '180px'}">
                          {{ $t('depositPdf.chequeNumber') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '120px'}">
                          {{ $t('depositPdf.bank') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '140px'}">
                          {{ $t('depositPdf.paymentDate') }}
                        </th>
                        <th v-if="accessRight.includes('financial')" class="text-center" :style="{width: '150px'}">
                          {{ $t('depositPdf.amount') }}
                        </th>
                        <th class="text-start ps-1" :style="{width: '150px'}">
                          {{ $t('depositPdf.type') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="model.grand_total == 0">
                        <td colspan="7" class="text-center">{{$t('common.noDataFound')}}</td>
                      </tr>
                      <tr v-else v-for="n in lastIndex[index-1] ? getNumbers(index == 1 ? 1 : lastIndex[index-2] ? lastIndex[index-2] : lastIndex[index-1],lastIndex[index-1] ? lastIndex[index-1] : depositPaymentObject.length + 1) :  lastIndex[index-2] ? getNumbers(lastIndex[index-2], depositPaymentObject.length + 1): depositPaymentObject.length" :key="n">
                        <td class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-end'">
                          {{n}}
                        </td>
                        <td class="ps-1" style="word-break:break-word;">
                          {{depositPaymentObject[n-1] ? depositPaymentObject[n-1].customer_name : ''}}
                        </td>
                        <td class="ps-1">
                          {{depositPaymentObject[n-1] ? depositPaymentObject[n-1].cheque_no : ''}}
                        </td>
                        <td class="ps-1">
                          {{depositPaymentObject[n-1] ? depositPaymentObject[n-1].bank_name : ''}}
                        </td>
                        <td class="ps-1">
                          {{depositPaymentObject[n-1] ? company.date_format? formatDateDDMMYYYY(depositPaymentObject[n-1].cheque_date): depositPaymentObject[n-1].cheque_date : ''}}
                        </td>
                        <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-end'">
                          {{depositPaymentObject[n-1] ? numberWithCommas(depositPaymentObject[n-1].amount) : 0.00}}
                        </td>
                        <td class="ps-1">
                          {{depositPaymentObject[n-1] ? depositPaymentObject[n-1].type : ''}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title v-if="index == printPage" class="py-0">
              <v-row dense no-gutters class="text-end">
                <v-col cols="9" class="text-body-2 text-start" style="word-break:break-word;">
                  </v-col>
                <v-col v-if="accessRight.includes('financial')" cols="3" class="text-body-2">
                  <v-row dense no-gutters>
                    <v-col cols="6" class="font-weight-bold">
                      {{$t('deliveryPdf.total')}}
                    </v-col>
                    <v-col cols="6" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                      {{numberWithCommas(model.total)}}
                    </v-col>
                  </v-row>
                  <v-row v-if="index === printPage" class="text-end">
                    <v-col cols="12" class="text-body-1" v-if="!Object.keys(pdfTemplateValueList).length">
                      {{$t('depositPdf.withRegards')}}<br>
                      {{model.employee_name}}<br>
                      <span class="text-body-2">{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                    <v-col cols="12" class="text-body-1" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
                      {{$t('depositPdf.withRegards')}}<br>
                      {{model.employee_name}}<br>
                      <span>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          </div>
          <div class="pb-0 px-4 pt-1" color="white">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-img width="100%" eager :src="bottom" v-if="index == 1 && bottom"/>
                <v-img width="100%" eager :lazy-src="bottom" v-if="index !== 1 && bottom"/>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-app>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {getAuxiliaryZtableValueByName,formatDateDDMMYYYY} from '@/utils';
export default {
  name: "PrintQuotation",
  props:['modelData', 'pdfTemplateValueList'],
  data() {
    return{
      company: '',
      printPage: 1,
      rowCount: 0,
      lastIndex: [],
      paymentGroupValue: [],
      bankList: [],
      topLogoUrl: null,
      bottomLogoUrl: null,
      totalLineDiscount: 0,
      totalLineItemDescription: null,
      depositPaymentObject: null,
      invoiceNumbers: null,
    }
  },
  async mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
    this.company = await this.$store.dispatch('profile/GetCompanyById');
    if(this.company.topLogo) {
      this.getBase64Image(this.company.topLogo.file_path, 'topLogo');
    }
    if(this.company.bottomLogo) {
      this.getBase64Image(this.company.bottomLogo.file_path, 'bottomLogo');
    }
    let tableNameValue = await getAuxiliaryZtableValueByName(8);
    if(Object.keys(tableNameValue).length > 0) {
      this.paymentGroupValue = tableNameValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
    }
    this.bankList = await this.$store.dispatch('bank/GetBank', {order_by: 'name|asc'});
  },
  watch: {
    modelData: {
      handler(){
        this.totalRow;
      },
      deep: true
    },
  },
  computed: {
    model() {
      return this.modelData;
    },
    bottom() {
      return this.bottomLogoUrl;
    },
    top() {
      return this.topLogoUrl;
    },
    ...mapGetters({
      locale: 'locale',
      accessRight: 'accessRight'
    }),
    totalRow() {
      let rowCount = 0;
      let beforeRowCount = rowCount;
      if(this.model.depositSub) {
        /* eslint-disable */
        this.depositPaymentObject = this.model.depositSub.filter((item) => {
          if(item.amount > 0) {
            return item;
          }
        })
        /* eslint-enable */
        this.depositPaymentObject.forEach((element,index) => {
          if(element.cheque_date && element.deposit_to_bank) {
            let customerNameCount = 0
            if(element.customer_name) {
              customerNameCount = element.customer_name.match(/.{1,15}/g).length-1 >= 1 ? Number(customerNameCount) + (Number(element.customer_name.match(/.{1,15}/g).length-1)) : Number(customerNameCount) + 1;
            }

            let chequeNoCount = 0;
            if(element.cheque_no) {
              chequeNoCount = element.cheque_no.match(/.{1,15}/g).length-1 >= 1 ? Number(chequeNoCount) + (Number(element.cheque_no.match(/.{1,15}/g).length-1)) : Number(chequeNoCount) + 1;
            }
            
            let bankCount = 0;
            if(element.bank) {
              bankCount = element.bank_name.match(/.{1,15}/g).length-1 >= 1 ? Number(bankCount) + (Number(element.bank_name.match(/.{1,15}/g).length-1)) : Number(bankCount) + 1;
            }

            let typeCount = 0;
            if(element.type) {
              typeCount = element.type.match(/.{1,15}/g).length-1 >= 1 ? Number(typeCount) + (Number(element.type.match(/.{1,15}/g).length-1)) : Number(typeCount) + 1;
            }

            rowCount = Number(rowCount) + Number(Math.max(chequeNoCount, bankCount, customerNameCount, typeCount));

            if(index == this.depositPaymentObject.length-1) {
              rowCount = Number(rowCount) + 5;
            }

            let tempCount = 0;
            if(tempCount < rowCount && rowCount >= 15) {
              tempCount = rowCount;
              this.lastIndex.push(this.printPage == 1 ? index+1 : index+1);
              this.printPage += 1;

              rowCount = Number(Math.max(chequeNoCount, bankCount, customerNameCount, typeCount)) + beforeRowCount;
            }
          }
        });
      }
      return rowCount;
    }
  },
  methods: {
    formatDateDDMMYYYY,
    getSelectedBankName(id) {
      return this.bankList.length > 0 ? this.bankList.filter(item => item.id == id ) : ''
    },
    getBase64Image(imgUrl, type) {
      let img = new Image();
      let self = this;
      // onload fires when the image is fully loadded, and has width and height
      img.onload = function(){
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png")
        type === 'bottomLogo' ? self.bottomLogoUrl = dataURL : self.topLogoUrl = dataURL; // the base64 string
      };
      // set attributes and src 
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = imgUrl + '?' + new Date().toLocaleString('sv-SE');
    },
    getNumbers:function(start,stop){
      return new Array(stop-start).fill(start).map((n,i)=>n+i);
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : 0;
    },
  }
}
</script>
<style scoped>
.product table {
  border-collapse: collapse;
}
.product table, .product table td {
  border: 0px solid black;
}
.product table {
  border-width: 1px 1px 1px 1px;
}
.product table td {
  border-width: 1px 0 0 1px;
}
.product th {
  border: 0px solid black;
  border-width: 0 0 0 1px;
}
#footer .v-image {
  width: 110px;
  right: 0;
  position: absolute;
  left: 0;
}
</style>