<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="60%"
    >
      <ContactListTable :listSkeleton.sync="listSkeleton" :customerId.sync="customerId" @selectQuotationContact="selectQuotationContact" :type.sync="type">
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false);">mdi-close</v-icon>
          </v-card-text>
        </template>
      </ContactListTable>
    </v-dialog>
  </div>
</template>
<script>
import ContactListTable from '@/views/companyApp/customer/Steps/ContactListTable';
export default {
  name: "ContactListDialog",
  props: ["dialog", "customerId"],
  components: {
    ContactListTable
  },
  data() {
    return {
      listSkeleton: false,
      type: 'QuotationContact',
    }
  },
  watch: {
    dialog(val) {
      if(!val) {
        this.type = null;
      } else {
        this.type = 'QuotationContact';
        this.$store.dispatch('contact/GetContact', {page: 1, limit: 10, where_and: 'record_type_number,record_type|'+this.customerId+',customers', order_by: 'name|asc'});
      }
    }
  },
  methods: {
    selectQuotationContact(item) {
      this.$emit('onContactChange', item);
      this.$emit('update:dialog', false);
    }
  },
}
</script>