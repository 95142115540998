<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t("auxiliaryTable.confirm") }}
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="text-body-1 mt-4">
        {{ periodClosureConfirmation ? periodClosureConfirmation : '' }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" class="text-capitalize" small @click="confirm">
          {{ $t("auxiliaryTable.confirm") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "PeriodClosureConfirmationDialog",
  props: ["dialog", "periodClosureConfirmation"],
  watch: {
    dialog() {
      if (!this.dialog) {
        this.confirm();
      }
    },
  },
  methods: {
    confirm() {
      if (this.dialog) {
        this.$emit("update:dialog", false);
      } 
    },
  },
};
</script>