<template>
  <v-btn
    :disabled="!id"
    color="primary"
    class="ml-auto"
    small
    outlined
    @click="suppliedQty"
  >
    {{ $t("common.suppliedQuantities") }}
  </v-btn>
</template>

<script>
export default {
  name: "SuppliedQuantityDialog",
  props: ["id"],
  methods: {
    suppliedQty() {
      this.$emit("suppliedQty");
    },
  },
};
</script>
