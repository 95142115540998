<template>
  <div>
    <v-dialog
      v-model="actDialog"
      @click:outside="$emit('update:actDialog', false)"
      max-width="70%"
    >
      <ActivityLogTable v-if="actDialog" :listSkeleton.sync="skeleton" :docId='docId' :docType='docType' :actDialog.sync="dialog" :docMode.sync="docMode" :docItems.sync="docItems" :docEmailSent.sync="docEmailSent" />
    </v-dialog>
  </div>
</template>
<script>
import ActivityLogTable from "@/components/activityLog/Tables/ActivityLogTable.vue";

export default {
  name: 'ActivityLogTableDialog',
  components: {
    ActivityLogTable
  },
  props: ['actDialog', 'docId', 'docType', 'listSkeleton', 'docMode', 'docItems', 'docEmailSent'],
  data() {
    return {
      dialog: false,
      skeleton: false
    }
  },
  watch: {
    async dialog(val) {
      if (!val) {
        this.$emit('update:actDialog', false)
      }
    },
    actDialog(val) {
      if (!val) {
        this.$emit('update:docItems', null)
        this.$emit('update:docEmailSent', null)
        this.$emit('update:docType', null)
        this.$emit('update:docMode', null)
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    }
  },
}
</script>