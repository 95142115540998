<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="60%"
    >
      <ProductListTable :listSkeleton.sync="listSkeleton" @selectQuotationProduct="selectQuotationProduct" :type.sync="type">
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false);">mdi-close</v-icon>
          </v-card-text>
        </template>
      </ProductListTable>
    </v-dialog>
  </div>
</template>
<script>
import ProductListTable from '@/views/companyApp/product/ProductListTable';
export default {
  name: "ProductDialog",
  props: ["dialog", "productDialogIndex", "mainDocIndexForMultiple"],
  components: {
    ProductListTable
  },
  data() {
    return {
      listSkeleton: false,
      type: 'QuotationProduct',
    }
  },
  watch: {
    dialog(val) {
      if(!val) {
        this.type = null;
        this.$emit('update:productDialogIndex', null);
        this.$emit('update:mainDocIndexForMultiple', null);
      } else {
        this.type = 'QuotationProduct';
        this.$store.dispatch('product/GetProduct', {page: 1, limit: 10, order_by: 'description|asc'});
      }
    }
  },
  methods: {
    selectQuotationProduct(item) {
      this.$emit('onProductChange', item, this.productDialogIndex, 'table', this.mainDocIndexForMultiple);
      this.$emit('update:dialog', false);
    }
  },
}
</script>