<template>
  <div>
    <v-bottom-sheet
      v-model="customerDetailDialog"
      @click:outside="$emit('update:customerDetailDialog', false);"
      inset
      overlay-opacity="0.1"
      width="50%"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
      >
        <v-card
          class="mx-auto"
          flat
        >
          <v-card-title class="py-1 primary--text">
            <v-icon color="primary">mdi-hand-pointing-right</v-icon>
            <h6 class="ms-2 font-weight-regular">{{$t('quotation.additionalCustomerDetail')}}</h6>
            <v-spacer />
            <v-btn
              color="primary"
              small
              class="text-end"
              :disabled="!valid || isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
              @click="saveAddidtionalDetail"
            >
              {{ $t('quotation.save') }}
            </v-btn>
            <v-icon @click="$emit('update:customerDetailDialog', false);" color="primary" class="text-end ms-2">mdi-close</v-icon>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row :dense="true">
              <v-col cols="12">
                <v-text-field
                  :readonly="isFieldReadable"
                  hide-details="auto"
                  v-model.trim="model.authorized_dealer_no"
                  :label="$t('quotation.field.authorizedDealerNo')"
                  :rules="authorizedDealerNoValid"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :readonly="isFieldReadable"
                  hide-details="auto"
                  v-model="model.email"
                  :label="$t('quotation.field.email')"
                  :rules="emailValid"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :readonly="isFieldReadable"
                  hide-details="auto"
                  v-model="model.country"
                  :label="$t('quotation.field.country')"
                  :rules="countryValid"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :readonly="isFieldReadable"
                  hide-details="auto"
                  v-model="model.phone_1"
                  :label="$t('quotation.field.tel1')"
                  :rules="phoneValid"
                  required
                />
                <v-text-field
                  :readonly="isFieldReadable"
                  hide-details="auto"
                  v-model="model.phone_2"
                  :label="$t('quotation.field.tel2')"
                  :rules="phoneValid"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :readonly="isFieldReadable"
                  hide-details="auto"
                  :label="$t('quotation.field.cel')"
                  v-model="model.cellular"
                  :rules="phoneValid"
                  required
                />
                <v-text-field
                  :readonly="isFieldReadable"
                  hide-details="auto"
                  :label="$t('quotation.field.fax')"
                  v-model="model.fax"
                  :rules="faxValid"
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-bottom-sheet>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: "CustomerDetail",
  props:['model', 'customerDetailDialog', 'isFieldReadable'],
  data() {
    return {
      valid: false,
    }
  },
  watch: {
    customerDetailDialog() {
      if(!this.customerDetailDialog) {
        this.$emit('update:model', {})
      }
    }
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight"
    }),
    emailValid() {
      return [
        v => !v || /.+@.+\..+/.test(v) || this.$t('formRules.emailValidMessage'),
        v => !v || (v && v.length <= 64) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('quotation.field.email'), type: this.$t('common.less'), size: 64 }),
      ];
    },
    phoneValid() {
      return [
        v => !v || /^[0-9]*$/.test(v) || this.$t('formRules.phoneNoInteger'),
        v => !v || (v && (v.length >= 7 && v.length <= 17)) || this.$t('formRules.phoneNoValidMessage'),
      ];
    },
    faxValid() {
      return [
        v => !v || /^[0-9]*$/.test(v) || this.$t('formRules.faxInteger'),
        v => !v || (v && (v.length >= 7 && v.length <= 17)) || this.$t('formRules.faxValidMessage'),
      ];
    },
    authorizedDealerNoValid() {
      return [
        v => !v || (v && String(v).length <= 30) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('quotation.field.authorizedDealerNo'), type: this.$t('common.less'), size: 30 }),
      ];
    },
    countryValid() {
      return [
        v => !v || (v && String(v).length <= 200) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('quotation.field.country'), type: this.$t('common.less'), size: 200 }),
      ];
    }
  },
  methods: {
    saveAddidtionalDetail() {
      this.$emit('update:model', this.model);
      this.$emit('saveCustomerAdditionalDetail');
      this.$emit('update:customerDetailDialog', false);
    }
  }
}
</script>