<template>
  <div>
    <v-card
      class="mx-auto"
      outlined
    >
      <ListSkeleton v-if="(!type && customerId && Object.keys(customerById).length <= 0) || (type && listSkeleton)"/>
      <v-card-text class="text-h5" :class="type ? 'px-2 pt-0' : 'pa-0'" v-else-if="contactList.data">
        <slot name="closeIcon"></slot>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          :items="contactList.data"
          :items-per-page="limit"
          :page.sync="page"
          :server-items-length="contactList.meta.total"
          :options.sync="pagination"
          item-class="py-4"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          :search="search"
          @dblclick:row="doubleClick"
        >
          <template
            v-slot:top
          >
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="8">
                </v-col>
                <v-col cols="4" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    :autofocus="type ? true : false"
                    hide-details="auto"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`item.actions`]="props">
            <v-menu
              v-if="(accessRight.includes('edit')) || (accessRight.includes('delete'))"
              offset-x
              left
              content-class="elevation-2"
              flat
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-if="accessRight.includes('edit')" dense class="primary--text" @click="contact_id = props.item.id, $emit('update:contactDialog', true);">
                  <v-list-item-content>
                    <v-list-item-title>{{$t('common.edit')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon small class="primary--text">mdi-circle-edit-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item v-if="accessRight.includes('delete')" dense @click="confirmationDialog = true, delete_item = props.item">
                  <v-list-item-content class="error--text">
                    <v-list-item-title>{{$t('common.delete')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon small class="error--text">mdi-delete-sweep-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{contactList.meta.total}}</span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    :length="contactList.meta.last_page"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
    </v-card>
    <ContactDialog :dialog.sync="contactDialog1" :contact_id.sync="contact_id"/>
    <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import ContactDialog from './ContactDialog.vue'
import {getAuxiliaryZtableValueByName} from '@/utils';
import ConfirmationDialog from '@/components/ConfirmationDialog';

export default {
  name: 'ContactListTable',
  props: ['type', 'customerId', 'contactDialog', 'listSkeleton'],
  components: {
    ListSkeleton,
    ContactDialog,
    ConfirmationDialog
  },
  data() {
    return {
      page: 1,
      limit: 10,
      contact_id: '',
      search: '',
      confirmationDialog: false,
      delete_item: null,
      pagination: {},
    }
  },
  computed: {
    contactDialog1: {
      get() {
        return this.contactDialog
      },
      set(val) {
        this.page = 1;
        this.limit = 10;
        this.search = '';
        this.$emit('update:contactDialog',val);
      }
    }, 
    params() {
      return {
        ...this.pagination,
        query: this.search
      };
    },
    ...mapGetters({
      contactList: 'contact/contactList',
      customerById: 'customer/customerById',
      accessRight: 'accessRight'
    }),
    headers() {
      return [
        {
          text: this.$t('roles.id'),
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: this.$t('contact.contactName'), value: "name" },
        !this.type ? { text: this.$t('roles.action'), value: "actions", align: 'center', sortable: false, class: this.type ? 'd-none' : '' } : ''
      ]
    }
  },
  watch: {
    type: {
      handler() {
        this.search = '';
        this.page = 1;
        this.limit = 10;
      },
      deep: true
    },
    params: {
      handler() {
        this.paginate();
      },
      deep: true
    }
  },
  async mounted() {
    this.$emit('update:listSkeleton', true);
    let tableNameValue = await getAuxiliaryZtableValueByName(2);
    if(Object.keys(tableNameValue).length > 0) {
      this.groupValue = tableNameValue.ztables;
    }
    if (this.customerId) {
      await this.$store.dispatch('contact/GetContact', {page: this.page, limit: this.itemsPerPage, where_and: 'record_type_number,record_type|'+this.customerId+',customers', order_by: 'name|asc'});
    }
    this.$emit('update:listSkeleton', false);
  },
  methods: {
    async paginate() {
      const { sortBy, sortDesc, page, itemsPerPage, query } = this.params;
      if (this.customerId) {
        await this.$store.dispatch('contact/GetContact', {page: page, limit: itemsPerPage, where_like: query ? 'id,name|'+query : 'id,name|', order_by: sortBy[0] ? sortBy[0]+'|'+(sortDesc[0] ? 'desc' : 'asc') : 'name|'+(sortDesc[0] ? 'desc' : 'asc'), where_and: 'record_type_number,record_type|'+this.customerId+',customers'});
      }
    },
    doubleClick(event, {item}) {
      if(!this.type) {
        this.contact_id = item.id;
        this.$emit('update:contactDialog', true);
      } else {
        this.$emit('selectQuotationContact', item);
      }
    },
    deleteItem(item) {
      this.search = '';
      this.page = 1;
      this.limit = 10;
      this.$emit('deleteItem', item);
    }
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>