<template>
    <div>
      <template>
        <v-app>
          <div v-for="index in printPage" :key="index" class="html2pdf__page-break">
            <div style="height: 1050px">
            <div
              class="mx-auto"
              style="min-height: 200px"
            >
              <v-card-title class="py-1" v-if="!Object.keys(pdfTemplateValueList).length">
                <v-row dense no-gutters style="width: 100%">
                  <v-col cols="12" class="text-center primary--text" v-if="top">
                    <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                    <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                  </v-col>
                  <v-col cols="12" class="text-center primary--text" v-else>
                    <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                    <h5 class="font-weight-regular mt-n2">Test Software By BINA</h5>
                    <h5 class="font-weight-regular mt-n2">{{ $t('invoicePdf.companyAddress') }}</h5>
                    <h5 class="font-weight-regular mt-n2">{{ $t('invoicePdf.tel') }}: 972-3-7361150 {{ $t('invoicePdf.fax') }}: 972-3-7361150</h5>
                    <h6 class="mt-n2">bina@binaw.com</h6>
                  </v-col>
                  <v-col cols="12">
                    <h5 class="font-weight-regular mt-n2">{{ $t('invoicePdf.vatRegNo') }} {{company.vat_reg_no}} </h5>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <h5 class="text-decoration-underline mt-n2 primary--text">
                      {{ $t('route.cashFlow') }}
                      <span class="primary--text text-decoration-underline float-end">
                        {{model.status == 0 ? $t('invoicePdf.original') : model.status == 1 ? $t('invoicePdf.copy') : model.status == 2 ? $t('invoicePdf.cancelled') : ''}}
                      </span>
                    </h5>
                  </v-col>
                </v-row>
                <v-row dense no-gutters>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.customer_group_id">
                    <span><b>{{$t('debitReport.group')}}:</b> {{payload.customer_group_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.customer_id">
                    <span><b>{{$t('debitReport.customer')}}:</b> {{payload.customer_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.supplier_group_id">
                    <span><b>{{$t('supplier.field.group')}}:</b> {{payload.supplier_group_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.supplier_id">
                    <span><b>{{$t('supplier.supplier')}}:</b> {{payload.supplier_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.from_invoice_date">
                    <span><b>{{$t('debitReport.fromInvoiceDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.from_invoice_date) : parseDateYYYYMMDD(payload.from_invoice_date)}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.to_invoice_date">
                    <span><b>{{$t('debitReport.toInvoiceDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.to_invoice_date) : parseDateYYYYMMDD(payload.to_invoice_date)}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.start_payment_date">
                    <span><b>{{$t('debitReport.fromPaymentDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.start_payment_date) : parseDateYYYYMMDD(payload.start_payment_date)}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center text-body-2 my-1 text-start" v-if="payload.end_payment_date">
                    <span><b>{{$t('debitReport.toPaymentDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.end_payment_date) : parseDateYYYYMMDD(payload.end_payment_date)}}</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-title class="py-1" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
                <v-row dense no-gutters style="width: 100%">
                  <v-col cols="12" class="text-center" v-if="top">
                    <v-img class="mx-auto" width="226px" height="128px" eager :src="top" v-if="index == 1 && top"/>
                    <v-img class="mx-auto" width="226px" height="128px" eager :lazy-src="top" v-if="index !== 1 && top"/>
                  </v-col>
                  <v-col cols="12" class="text-center" v-else>
                    <h2>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</h2>
                    <h5 class="mt-n2">Test Software By BINA</h5>
                    <h5 class="mt-n2">{{ $t('invoicePdf.companyAddress') }}</h5>
                    <h5 class="mt-n2">{{ $t('invoicePdf.tel') }}: 972-3-7361150 {{ $t('invoicePdf.fax') }}: 972-3-7361150</h5>
                    <h6 class="mt-n2">bina@binaw.com</h6>
                  </v-col>
                  <v-col cols="12">
                    <h5 class="mt-n2">{{ $t('invoicePdf.vatRegNo') }} {{company.vat_reg_no}} </h5>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <h5 class="mt-n2">
                      {{ $t('route.cashFlow') }}
                      <span class="float-end">
                        {{model.status == 0 ? $t('invoicePdf.original') : model.status == 1 ? $t('invoicePdf.copy') : model.status == 2 ? $t('invoicePdf.cancelled') : ''}}
                      </span>
                    </h5>
                  </v-col>
                </v-row>
                <v-row dense no-gutters>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.customer_group_id">
                    <span><b>{{$t('debitReport.group')}}:</b> {{payload.customer_group_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.customer_id">
                    <span><b>{{$t('debitReport.customer')}}:</b> {{payload.customer_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.supplier_group_id">
                    <span><b>{{$t('supplier.field.group')}}:</b> {{payload.supplier_group_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.supplier_id">
                    <span><b>{{$t('supplier.supplier')}}:</b> {{payload.supplier_id}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.from_invoice_date">
                    <span><b>{{$t('debitReport.fromInvoiceDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.from_invoice_date) : parseDateYYYYMMDD(payload.from_invoice_date)}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.to_invoice_date">
                    <span><b>{{$t('debitReport.toInvoiceDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.to_invoice_date) : parseDateYYYYMMDD(payload.to_invoice_date)}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.start_payment_date">
                    <span><b>{{$t('debitReport.fromPaymentDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.start_payment_date) : parseDateYYYYMMDD(payload.start_payment_date)}}</span>
                  </v-col>
                  <v-col cols="4" class="text-center my-1 text-start" v-if="payload.end_payment_date">
                    <span><b>{{$t('debitReport.toPaymentDate')}}:</b> {{profileById.date_format ? formatDateDDMMYYYY(payload.end_payment_date) : parseDateYYYYMMDD(payload.end_payment_date)}}</span>
                  </v-col>
                </v-row>
              </v-card-title>
            </div>
            <div
              class="mx-auto"
              flat
              ref="itemContent"
            >
              <v-card-title class="py-1">
                <v-row dense>
                  <v-col cols="12" class="text-body-2 py-0 text-end">
                    {{ $t('invoicePdf.page') }} {{index}} {{ $t('invoicePdf.of') }} {{printPage}}
                  </v-col>
                  <v-col cols="12" class="product">
                    <table
                      width="100%"
                      class="my-0 text-body-2"
                      v-if="invoiceItemsObject.length > 0"
                    >
                      <thead v-if="row === 'detailed'">
                        <tr>
                          <th class="text-start ps-1" :style="{width: '70px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.detailed.date') }}
                          </th>
                          <th class="text-start ps-1" :style="{width: '80px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.detailed.type') }}
                          </th>
                          <th class="text-start ps-1" :style="{width: '70px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.detailed.accountNo') }}
                          </th>
                          <th class="text-start ps-1" :style="{width: '80px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.detailed.name') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.detailed.credit') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.detailed.debit') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.detailed.balance') }}
                          </th>
                        </tr>
                      </thead>
                      <thead v-else-if="row === 'daily'">
                        <tr>
                          <th class="text-start ps-1" :style="{width: '70px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.daily.date') }}
                          </th>
                          <th class="text-start ps-1" :style="{width: '70px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.daily.day') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.daily.credit') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.daily.debit') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.daily.dailyBalance') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.daily.balance') }}
                          </th>
                        </tr>
                      </thead>
                      <thead v-else-if="row === 'monthly'">
                        <tr>
                          <th class="text-start ps-1" :style="{width: '40px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.monthly.month') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.monthly.incoming') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.monthly.outgoing') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.monthly.totalMonthly') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{width: '90px', wordBreak: 'break-word'}">
                            {{ $t('cashFlow.monthly.balance') }}
                          </th>
                        </tr>
                      </thead>
                      <thead v-else>
                        <tr>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{wordBreak: 'break-word'}">
                            {{ $t('cashFlow.focused.incoming') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{wordBreak: 'break-word'}">
                            {{ $t('cashFlow.focused.outgoing') }}
                          </th>
                          <th v-if="accessRight.includes('financial')" class="text-start ps-1" :style="{wordBreak: 'break-word'}">
                            {{ $t('cashFlow.focused.balance') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="invoiceItemsObject && invoiceItemsObject.length == 0">
                          <td colspan="7" class="text-center">{{$t('common.noDataFound')}}</td>
                        </tr>
                        <template v-else v-for="n in lastIndex[index-1] ? getNumbers(index == 1 ? 1 : lastIndex[index-2] ? lastIndex[index-2] : lastIndex[index-1],lastIndex[index-1] ? lastIndex[index-1] : invoiceItemsObject.length + 1) :  lastIndex[index-2] ? getNumbers(lastIndex[index-2], invoiceItemsObject.length + 1): invoiceItemsObject.length">
                            <tr :key="n" v-if="row === 'detailed' && invoiceItemsObject && invoiceItemsObject.length > 0 && invoiceItemsObject[n-1] && !invoiceItemsObject[n-1].customerData">
                            <td class="ps-1">
                              <span>{{ invoiceItemsObject[n-1].payment_date && (profileById.date_format ? formatDateDDMMYYYY(new Date(invoiceItemsObject[n-1].payment_date).toISOString().substring(0, 10)) : new Date(invoiceItemsObject[n-1].payment_date).toISOString().substring(0, 10))}}</span>
                            </td>
                            <td class="ps-1">
                              <span>{{ typeHebrew.includes(invoiceItemsObject[n-1].payment_type) ? $t(`cashFlow.${invoiceItemsObject[n-1].payment_type}`) : "" }}</span>
                            </td>
                            <td class="ps-1">
                              <span>{{ invoiceItemsObject[n-1].account_no }}</span>
                            </td>
                            <td class="ps-1">
                              <span>{{ invoiceItemsObject[n-1].customer_name ? invoiceItemsObject[n-1].customer_name : invoiceItemsObject[n-1].supplier_name ? invoiceItemsObject[n-1].supplier_name : "" }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].credit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].debit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].balance) }}</span>
                            </td>
                          </tr>
                          <tr :key="n" v-else-if="row === 'daily' && invoiceItemsObject && invoiceItemsObject.length > 0 && invoiceItemsObject[n-1] && invoiceItemsObject[n-1].customerData">
                            <td class="ps-1">
                              <span>{{profileById.date_format ? formatDateDDMMYYYY(invoiceItemsObject[n-1].customerData.date) : parseDateYYYYMMDD(invoiceItemsObject[n-1].customerData.date)}}</span>
                            </td>
                            <td class="ps-1">
                              <span>{{ days.includes(invoiceItemsObject[n-1].customerData.day) ? $t(`cashFlow.days.${invoiceItemsObject[n-1].customerData.day}`) : '' }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.totalcredit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.totaldebit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.total) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.balance) }}</span>
                            </td>
                          </tr>
                          <tr :key="n" v-else-if="row === 'monthly' && invoiceItemsObject && invoiceItemsObject.length > 0 && invoiceItemsObject[n-1] && invoiceItemsObject[n-1].customerData">
                            <td class="ps-1">
                              <span>{{ invoiceItemsObject[n-1].customerData.month }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.totalcredit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.totaldebit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.total) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.balance) }}</span>
                            </td>
                          </tr>
                          <tr :key="n" v-else-if="row === 'focused' && invoiceItemsObject && invoiceItemsObject.length > 0 && invoiceItemsObject[n-1] && invoiceItemsObject[n-1].customerData">
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.totalcredit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.totaldebit) }}</span>
                            </td>
                            <td v-if="accessRight.includes('financial')" class="ps-1" :class="locale === 'he' ? 'text-start' : 'text-start'">
                              <span>{{ numberWithCommas(invoiceItemsObject[n-1].customerData.balance) }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-title v-if="index == printPage" class="py-0">
                <v-row dense no-gutters class="text-end">
                  <v-col cols="8" class="text-body-2 text-start">
                  </v-col>
                  <v-col v-if="accessRight.includes('financial')" cols="4" class="text-body-2">
                    <v-row dense no-gutters v-if="row !== 'focused'">
                      <v-col cols="6" class="font-weight-bold">
                        {{$t('cashFlow.totalcredit')}}
                      </v-col>
                      <v-col cols="6" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                        {{numberWithCommas(model.sumTotalCredit)}}
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters v-if="row !== 'focused'">
                      <v-col cols="6" class="font-weight-bold">
                        {{$t('cashFlow.totaldebit')}}
                      </v-col>
                      <v-col cols="6" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                        {{numberWithCommas(model.sumTotalDebit)}}
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters v-if="row !== 'focused'">
                      <v-col cols="6" class="font-weight-bold">
                        {{$t('invoice.total')}}
                      </v-col>
                      <v-col cols="6" class="font-weight-bold ps-2" :class="locale === 'he' ? 'text-start' : 'text-end'">
                        {{numberWithCommas(model.sumBalance)}}
                      </v-col>
                    </v-row>
                    <v-row v-if="index === printPage" class="text-end">
                      <v-col cols="12" class="text-body-1" v-if="!Object.keys(pdfTemplateValueList).length">
                        {{$t('invoicePdf.withRegards')}}<br>
                        {{model.employee ? model.employee.name : user.name}}<br>
                        <span class="text-body-2">{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                      </v-col>
                      <v-col cols="12" v-else :style="`font-size:${pdfTemplateValueList.font_size}; font-style:${pdfTemplateValueList.font_style}; color:${pdfTemplateValueList.text_color}; font-weight:${pdfTemplateValueList.font_weight}`">
                        {{$t('invoicePdf.withRegards')}}<br>
                        {{model.employee ? model.employee.name : user.name}}<br>
                        <span>{{locale === 'he' && company.name_he ? company.name_he : company.name}}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
            </div>
            </div>
            <div class="pb-0 px-4 pt-1" color="white">
              <v-row dense no-gutters>
                <v-col cols="12">
                  <v-img width="100%" eager :src="bottom" />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-app>
      </template>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';
  export default {
    name: "PrintCashFlowReport",
    props:['modelData', 'payload', 'pdfTemplateValueList', 'row'],
    data() {
      return{
        company: '',
        printPage: 1,
        rowCount: 0,
        lastIndex: [],
        topLogoUrl: null,
        bottomLogoUrl: null,
        totalLineDiscount: 0,
        totalLineItemDescription: null,
        invoiceItemsObject: [],
        typeHebrew: ["receipt_payment", "receipt_tax_invoice_payment", "supplier_receipt_payment"],
        days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      }
    },
    async mounted() {
      this.company = await this.$store.dispatch('profile/GetCompanyById');
      if(this.company.topLogo) {
        this.getBase64Image(this.company.topLogo.file_path, 'topLogo');
      }
      if(this.company.bottomLogo) {
        this.getBase64Image(this.company.bottomLogo.file_path, 'bottomLogo');
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.$emit("domRendered");
        }, 1000);
      });
    },
    watch: {
      modelData: {
        async handler(){
          this.totalRow;
        },
        deep: true
      },
    },
    computed: {
      model() {
        return this.modelData;
      },
      bottom() {
        return this.bottomLogoUrl;
      },
      top() {
        return this.topLogoUrl;
      },
      ...mapGetters({
        locale: 'locale',
        accessRight: 'accessRight',
        user: 'user',
        profileById: 'profile/profileById',
      }),
      totalRow() {
        let rowCount = 0;
        /* eslint-disable */
        this.printPage = 1;
        let beforeRowCount = rowCount;
        if(this.model.data) {
          /* eslint-disable */
          this.invoiceItemsObject = [];
          this.lastIndex = [];
          this.model?.data?.forEach(mainItem => {
            // if (this.row !== 'detailed') {
              mainItem?.invoiceData?.forEach(element => {
                this.invoiceItemsObject.push(element);
              });
            // }
            if (this.row === 'detailed') {
              delete mainItem.invoiceData;
            }
            this.invoiceItemsObject.push({customerData: mainItem});
          })
          /* eslint-enable */
          this.invoiceItemsObject?.forEach((element,index) => {
              let allItemCustomerNameCount = 0;
              if(index == this.invoiceItemsObject.length - 1) {
                rowCount = Number(rowCount) + 5;
              }
              if (element.customerData) {
                let itemCustomerNameCount = 0;
                if((this.row === 'daily' || this.row === 'monthly' || this.row === 'focused')) {
                  let bb = element.customerData.balance.toString();
                  itemCustomerNameCount = bb.match(/.{1,19}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(bb.match(/.{1,19}/g).length)) : Number(itemCustomerNameCount) + 1;
                  allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
                  rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
                } else {
                  rowCount = Number(rowCount) + 1;
                }
  
              } else if (this.row === 'detailed') {
                let itemCustomerNameCount = 0;
                if(element.customer_name) {
                  itemCustomerNameCount = element.customer_name.match(/.{1,15}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(element.customer_name.match(/.{1,15}/g).length)) : Number(itemCustomerNameCount) + 1;
                  allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
                  rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
                } else if (element.supplier_name) {
                  itemCustomerNameCount = element.supplier_name.match(/.{1,15}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(element.supplier_name.match(/.{1,15}/g).length)) : Number(itemCustomerNameCount) + 1;
                  allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
                  rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
                } else {
                  rowCount = Number(rowCount) + 1;
                }

              } else if (this.row !== 'detailed') {
                let itemCustomerNameCount = 0;
                if(element.balance) {
                  let bb = element.customerData.balance.toString();
                  itemCustomerNameCount = bb.match(/.{1,19}/g).length-1 >= 1 ? Number(itemCustomerNameCount) + (Number(bb.match(/.{1,19}/g).length)) : Number(itemCustomerNameCount) + 1;
                  allItemCustomerNameCount = Number(allItemCustomerNameCount) + Number(itemCustomerNameCount);
                }
  
                rowCount = Number(rowCount) + Number(allItemCustomerNameCount);
              }
              let tempCount = 0;
              if(tempCount < rowCount && rowCount >= 30) {
                tempCount = rowCount;
                this.lastIndex.push(this.printPage == 1 ? index+1 : index+1);
                this.printPage += 1;
                rowCount = Number(allItemCustomerNameCount) + beforeRowCount;
              }
            });
  
        }
        return rowCount;
      }
    },
    methods: {
      parseDateYYYYMMDD:parseDateYYYYMMDD,
      formatDateDDMMYYYY: formatDateDDMMYYYY,
      getNowTime(newDate) {
        if(!newDate) return null;
        const date = new Date()
        return ('0'+(date.getHours())).slice(-2)+':'+('0'+(date.getMinutes())).slice(-2);
      },
      getNowDate(newDate) {
        if(!newDate) return null;
        const date = new Date()
        return date.getFullYear()+'-'+('0'+(date.getMonth()+1)).slice(-2)+'-'+date.getDate();
      },
      getBase64Image(imgUrl, type) {
        let img = new Image();
        let self = this;
        // onload fires when the image is fully loadded, and has width and height
        img.onload = function(){
          let canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          let dataURL = canvas.toDataURL("image/png")
          type === 'bottomLogo' ? self.bottomLogoUrl = dataURL : self.topLogoUrl = dataURL; // the base64 string
        };
        // set attributes and src 
        img.setAttribute('crossOrigin', 'anonymous'); //
        img.src = imgUrl + '?' + new Date().toLocaleString('sv-SE');
      },
      getNumbers:function(start,stop){
        return new Array(stop-start).fill(start).map((n,i)=>n+i);
      },
      numberWithCommas(x, type = 'float') {
        return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : 0;
      },
    }
  }
</script>

<style scoped>
.product table {
border-collapse: collapse;
}
.product table, .product table td {
border: 0px solid black;
}
.product table {
border-width: 1px 1px 1px 1px;
}
.product table td {
border-width: 1px 0 0 1px;
border-left: none;
border-right: none;
}
.product th {
border: 0px solid black;
border-width: 0 0 0 1px;
border-left: none;
border-right: none;
}
#footer .v-image {
width: 110px;
right: 0;
position: absolute;
left: 0;
}
</style>
  